<template lang="html">
  <div class="MatrixExtended">
    <div class="MatrixExtended--Radios">
      <p v-for="(txt,i) in question.question.radioOptionTexts" v-html="txt"></p>
    </div>
    <div class="MatrixExtended--Values">
      <p v-for="(v,index) in question.question.values" v-html="v.value"></p>
    </div>
    <div class="MatrixExtended--Select">
      <div class="MatrixExtended--SelectionBox" v-for="(v,index) in question.question.values">
        <div class="">
          <select v-for="(txt,i) in question.question.radioOptionTexts" v-model="$parent.answers[index][i]" :class="{'MatrixExtended--smallSize':$parent.answers[index][i] == 99}" @change="optionSelected()">
            <option :value="99">{{$parent.translationMethod('question_not_answer')}}</option>
            <option v-for="(rankIndex,x) in question.question.rankingMax+1" :value="x" v-show="x<=question.question.rankingMax && x>=question.question.rankingMin">{{x}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MatrixExtended',

  props: {
    question:Object
  },
  methods:{
    optionSelected(){
      this.$emit('send-answers', {answer:{matrixVals:this.$parent.answers},question:this.question, answerValid: true})
    }
  },
  created(){
    this.optionSelected()
  }
}
</script>

<style lang="scss" scoped>
select{
  outline: none;
  text-align-last:center;
  height: 25px;
}
.MatrixExtended{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    font-size: 15px;
    &--smallSize{
      font-size: 12px;
    }
    p{
      margin:0;
      font-weight: bold;
    }
    &--Radios{
      grid-column:1;
      grid-row:2;
      grid-auto-rows: 61px;
      display: grid;
    }
    &--Values{
      grid-column:2;
      grid-row:1;
      display: flex;
      p{
        writing-mode: vertical-rl;
        width: 61px;
        transform: rotate(180deg);
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
    }
    &--Select{
      grid-column:2;
      grid-row:2;
      display: flex;
      overflow-x: auto;
      &::-webkit-scrollbar-track{
        background-color: #e6e6e6;
      }
      &::-webkit-scrollbar {
        background:linear-gradient(to right,var(--main-color) , var(--secondary-color)); ;
        height: 10px;
        width: 10px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-image: -webkit-gradient(linear,40% 0%,75% 84%,from(var(--main-color)),to(var(--secondary-color)),color-stop(.6,var(--secondary-color)));
      }

    }
    &--SelectionBox{
      display: flex;
      flex-direction: column;
      margin-right: 5px;
      div{
        display: grid;
        grid-auto-rows: 61px;
        grid-auto-columns: 61px;

      }
    }
}
</style>
