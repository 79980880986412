<template lang="html">
<div class="Survey">
  <QuestionsBlock
    ref="questionsBlock"
    :key="questionsBlockKey"
    :questions="questionsBlock.questions"
    :uploadHeaders="uploadHeaders"
    :uploadUrl="uploadUrl"
    :currentQuestionIndex="currentQuestionIndex"
    :currentQuestionOrder="currentQuestionOrder"
    :isSavingAnswer="isSavingAnswer"
    :enviroment="'EL'"
    v-on:previous-question="handlePreviousQuestion($event)"
    v-on:save-answer="handleSaveAnswer($event)"
  />
</div>
</template>

<script>
import Swal from 'sweetalert2'
import store from '@/store';
import {
  FETCH_NEXT_QUESTION_BLOCK,
  FETCH_NEXT_QUESTION_BLOCK_PREVIEW,
  START_TEST_EXEC,
  START_PREVIEW_EXEC,
  FETCH_TESTER_COUNTRY,
  ADD_TEST_QUESTION,
  ADD_TEST_QUESTION_PREVIEW,
  FETCH_TEST_EXECUTION,
  FETCH_TEST_PREVIEW_TOKEN
} from '@/store/surveyExecution.module';
import {
  COMMUNITY_FETCH_ACTIVITY,
    COMMUNITY_FETCH_ACTIVITIES,
  COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE,
} from '@/store/community/activities.module';
import QuestionsBlock from '~/components/QuestionsBlock'
import ApiService from '~/services/api.service';
import { mapGetters } from 'vuex';
import { map, flatMap } from 'lodash';
import cookies from 'browser-cookies';

export default {
  name: 'SurveyExecution',

  components:{
    QuestionsBlock
  },

  computed: {
    ...mapGetters([
      'theme',
      'communityCurrentActivity',
      'communityCurrentActivityId',
      'isClient',
      'isModerator',
      'isUser',
      'origin',
      'communityInfo',
    ]),
  },

  data(){
    return{
      activity:{},
      execution:{},
      questionsBlock:{},
      uploadHeaders: ApiService.getConfig().headers,
      //uploadUrl: process.env.API_URL + '/file',
      uploadUrl: 'https://api.wearetesters.com/file',
      currentQuestionIndex: 0,
      currentQuestionOrder:1,
      questionsBlockKey: 1,
      isSavingAnswer: false,
      pollActivity:false,
      isPreview:false,
      publicToken:null,
    }
  },

  async mounted(){
    if(this.$route.name != 'activities.survey') this.isPreview = true
    this.generateCssVars()
    await store.dispatch(FETCH_TESTER_COUNTRY)
    .then((data) => {
      if(data == undefined) this.execution.ip = "not_retrieved"
      else this.execution.ip = data.ip ? data.ip : "not_retrieved"
    })
    await this.fetchSurvey()
    if ( this.pollActivity ) this.generatePollQuestion()
    else{
      if(this.isPreview) await this.fetchToken()
      if(this.communityCurrentActivity.userHasStartedSurvey) await this.continueExecution()
      else await this.startExecution()
      await this.fetchQuestionsBlock()
    }
  },

  methods:{
    async fetchToken(){
      await store.dispatch(FETCH_TEST_PREVIEW_TOKEN)
      .then((data) => {
        this.publicToken = data.visitorToken
      })
    },
    async redirectActivity(){
      if(this.isPreview){
        Swal.fire({
          title:"Encuesta finalizada correctamente",
          icon:"success",
          customClass:{popup:"swal-customSuccess"}
        })
        this.$emit('finished')
        return
      }
        await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, { priority: 'myActivities' });
      this.$router.push({ name: 'activity.info', params: { id: this.communityCurrentActivityId } })
    },
    generateCssVars(){
      let root = document.documentElement;
      root.style.setProperty('--button-color', this.theme.buttonColor);
    },
    async fetchSurvey(){
      await store.dispatch(COMMUNITY_FETCH_ACTIVITY, {activityId:this.$route.params.id})
      .then((data) => {
        this.activity = data
        this.pollActivity = data.type == "WAT_POLL" ? true : false
      })
    },
    async continueExecution(){
      await store.dispatch(FETCH_TEST_EXECUTION,{testId:this.activity.surveyId,hash:this.activity.surveyPreviewHash})
      .then((data) => {
        this.execution = data
      })
    },
    async startExecution(){
      let data = {
        client :cookies.get('mobileView') == 1 ? 'app' : 'web',
        device : navigator.userAgent,
        ip     : this.execution.ip,
        externalData : "{}",
        language: 'es'
      }
      await store.dispatch(this.isPreview ? START_PREVIEW_EXEC : START_TEST_EXEC,{testId:this.activity.surveyId, hash:this.activity.surveyPreviewHash, data:data, token:this.publicToken})
      .then(({data}) => {
        this.execution = data
      })
      .catch(({response}) => {
        if(response.data.reason == "ALREADY_COMPLETED"){
          Swal.fire({
            text:"Ya has respondido a este test",
            icon:"error",
            customClass:{popup:"swal-customError"}
          })
          this.redirectActivity()
        }
      })
    },
    async fetchQuestionsBlock(){
      await store.dispatch(this.isPreview ? FETCH_NEXT_QUESTION_BLOCK_PREVIEW : FETCH_NEXT_QUESTION_BLOCK,{testId:this.activity.surveyId,hash:this.activity.surveyPreviewHash,token:this.publicToken})
      .then((data) => {
        if(data.step == "TEST_ENDED"){
          this.redirectActivity()
        }
        this.currentQuestionIndex = 0
        this.questionsBlock = data
        // check the last question answered
        for(let q of this.questionsBlock.questions){
          if (q.answer) {
            this.currentQuestionIndex++
          }
          if(q.question.video && q.question.video.includes("youtu.be")){
            let video = q.question.video
            q.question.video = "https://www.youtube.com/watch?v=" + video.substring(video.indexOf('.be/')+4)
          }
          if (q.question.type == "SELECTION" || q.question.type == "MULTISELECTION"){
            let orders = Array.from(Array(q.question.values.length).keys())
            let elementsPerColumn = Math.ceil(q.question.values.length / 2);
            for(let i in q.question.values){
              if (elementsPerColumn - 1 >= i){
                q.question.values[i].order =  parseInt(i) + parseInt(i)
                orders.splice(orders.indexOf(q.question.values[i].order),1)
              }
              else{
                q.question.values[i].order =  orders[0]
                orders.splice(0,1)
              }
            }
          }
        }
        if(this.currentQuestionIndex == this.questionsBlock.questions.length) this.currentQuestionIndex--
      })
    },
    handlePreviousQuestion(ev){
      this.currentQuestionIndex--
      this.currentQuestionOrder--
    },
      async handleSaveAnswer(e) {
          if (this.pollActivity) return this.handleSaveAnswerPollActivity(e);
          this.isSavingAnswer = true;
          try {
              const data = await store.dispatch(
                  this.isPreview ? ADD_TEST_QUESTION_PREVIEW : ADD_TEST_QUESTION,
                  {
                      testId: this.activity.surveyId,
                      hash: this.activity.surveyPreviewHash,
                      token: this.publicToken,
                      taskNum: 1,
                      questionId: e.question.question.id,
                      data: {
                          answer: e.answer,
                      },
                  }
              );
              this.isSavingAnswer = false;
              if (data.nextStep == "NEXT_QUESTION") {
                  this.currentQuestionOrder++;
                  if (
                      this.currentQuestionIndex ==
                      this.questionsBlock.questions.length - 1
                  )
                      await this.fetchQuestionsBlock();
                  else this.currentQuestionIndex++;
              } else if (data.nextStep == "TEST_FINISHED") {
                  if (!this.isPreview) {
                      Swal.fire({
                          title: this.$t("activity_thanks_participated"),
                          text: this.communityCurrentActivity.bigActivity ? this.$t("survey_execution_finished") : this.$t("survey_execution_finished_opinion"),
                          icon: "success",
                          customClass: { popup: "swal-customSuccess" },
                      });
                  }
                  await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, { priority: 'myActivities' });
                  this.redirectActivity();
              } else if (
                  data.nextStep == "TRAP_QUESTION" ||
                  data.nextStep == "REQUIRED_NOT_SELECTED" ||
                  data.nextStep == "EXCLUSIVE_OPTION"
              ) {
                  this.redirectActivity();
              } else if (
                  data.nextStep == "QUALITY_KILLER" ||
                  data.data.error == "qualityKiller"
              ) {
                  this.redirectActivity();
              } else if (data.data.payload == "Number value too small") {
                  this.$refs.questionsBlock.setErrorText("Introduce un valor mayor.");
              } else if (data.data.payload == "Number value too big") {
                  this.$refs.questionsBlock.setErrorText("Introduce un valor menor.");
              } else if (data.data.error == "trap question catched") {
                  this.redirectActivity();
              } else if (
                  data.data.error == "A server error occurred: Not current question" ||
                  data.data.payload == "Not current question"
              ) {
                  Swal.fire(
                      "Ups! Algo no va bien. Prueba a salir del test y volver a entrar. No te preocupes, tus respuestas no se perderán."
                  );
              } else if (data.data.error == "A server error occurred: null") {
                  Swal.fire("La respuesta no ha podido guardarse, inténtalo de nuevo");
              } else {
                  if (!navigator.onLine) {
                      Swal.fire("¡Vaya! Parece que has perdido la conexión");
                      return;
                  }
              }
          } catch (error) {
              this.isSavingAnswer = false;
              console.error("Error al guardar la respuesta:", error);
          }
      },
    async handleSaveAnswerPollActivity(answer){
      let answerFormated = {
        questionId:answer.question.question.id,
        values: answer.question.question.type == "RANKING" ? [answer.answer.val] : (answer.question.question.type == "MULTISELECTION" ? answer.answer.multipleVals : [answer.answer.val])
      }
      await store.dispatch(COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE, {
        activityId: this.$route.params.id,
        data: [answerFormated],
      })
      .then((data) => {
        this.surveyErrors = []
        if(data == "" || data == undefined || data == null || data.participatedMessage){
          Swal.fire(this.$t('activity_answer_saved'),this.$t('activity_thanks_participated'))
          this.redirectActivity()
        }
      });
    },
    generatePollQuestion(){
      let question = this.activity.communityQuestions[0]
      let q = {
        id : question.identifier,
        order: 1,
        text : question.questionText,
        rankingMax : question.type == 'RANKING' ? question.maxValue : undefined,
        rankingMin : question.type == 'RANKING' ? question.minValue : undefined,
        rankingType : question.type == 'RANKING' ? "NUMBERS" : undefined,
        type : question.type == 'RANKING' ? question.type : (question.type == 'MULTI_SELECT' ? 'MULTISELECTION' : 'SELECTION')
      }
      if(question.type == "SINGLE_SELECT" || question.type == "MULTI_SELECT"){
        q.values = []
        for(let i in question.options){
          q.values.push({
            id:i,
            order:i,
            value:question.options[i].value
          })
        }
      }
      this.questionsBlock = {questions:[{question:q}]}
    },
  },
}
</script>
<style scoped lang="scss">
.Survey{
  width: 100%;
  padding: 5%;
  height: calc(100vh - 70px);
  overflow-y: auto;
  &::-webkit-scrollbar-track{
    background-color: #e6e6e6;
  }
  &::-webkit-scrollbar {
    background-color: #8134ff;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: var(--primary-color);
  }
  .QuestionsBlock{
    padding-bottom: 0px;
  }
}
</style>
<style lang="scss">
:root {
    --text-color: #ffffff;
    --main-color: #ff1565;
    --button-color: var(--accent-color);
    --contrast-color: #744ab7;
    --secondary-color:#fd7328;
}
button{
  font-family: Roboto condensed!important;
  cursor: pointer;
}
</style>
