<template lang="html">
  <div class="RatingFaces" :class="{'IE11':isIE11()}">
    <div class="RatingFaces__Icons">
      <div class="RatingFaces__Option" v-for="v in (question.question.rankingMin, question.question.rankingMax)" v-on:click="sendAnswers(v)">
        <input type="radio" :name="'answer_'+question.question.order+'_'+v" :id="'answer_'+question.question.order+'_'+v" :value="v" v-model="answer">
        <label :for="'answer_'+question.question.order+'_'+v">
          <span>
            <svg  v-if="v == 1" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">
              <metadata>
              Created by potrace 1.15, written by Peter Selinger 2001-2017
              </metadata>
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M2240 5105 c-462 -60 -856 -222 -1240 -509 -122 -91 -385 -354 -476
              -476 -268 -358 -434 -747 -501 -1170 -26 -164 -26 -616 0 -780 82 -519 319
              -998 680 -1376 394 -412 874 -667 1441 -766 174 -30 543 -37 727 -13 1188 149
              2110 1084 2239 2272 16 145 8 533 -14 667 -184 1134 -1088 2011 -2216 2151
              -150 18 -501 18 -640 0z m505 -275 c565 -52 1064 -290 1450 -691 347 -360 558
              -797 626 -1299 19 -139 17 -450 -5 -590 -87 -577 -364 -1072 -805 -1441 -318
              -266 -712 -441 -1136 -505 -163 -24 -466 -25 -625 -1 -412 63 -785 224 -1104
              475 -107 84 -323 305 -402 412 -238 321 -381 665 -440 1055 -25 165 -25 466 0
              630 114 758 582 1400 1261 1730 377 183 786 261 1180 225z"/>
              <path d="M1771 4026 c-23 -31 -59 -81 -81 -111 -22 -30 -40 -56 -40 -59 0 -9
              434 -319 448 -320 17 -1 176 210 168 223 -8 12 -420 309 -439 317 -10 4 -28
              -13 -56 -50z"/>
              <path d="M3084 3930 c-115 -83 -215 -157 -223 -164 -11 -12 0 -31 66 -122 44
              -60 86 -109 94 -108 14 1 404 277 438 309 11 11 0 31 -66 121 -43 59 -83 109
              -89 111 -6 2 -105 -64 -220 -147z"/>
              <path d="M1604 3394 c-59 -21 -118 -76 -148 -136 -53 -106 -26 -264 56 -333
              113 -95 271 -99 377 -9 83 71 118 191 87 296 -19 65 -79 139 -140 170 -53 28
              -173 34 -232 12z"/>
              <path d="M3291 3379 c-98 -49 -150 -136 -151 -249 0 -246 291 -372 472 -202
              114 107 117 291 6 402 -85 85 -215 105 -327 49z"/>
              <path d="M2310 2545 c-497 -83 -929 -392 -1160 -826 -27 -52 -50 -99 -50 -105
              0 -8 158 -85 251 -122 4 -2 28 36 53 85 124 239 351 457 597 572 188 88 349
              125 554 125 161 1 248 -13 395 -60 331 -106 612 -340 766 -636 l45 -88 32 14
              c138 57 227 101 227 110 0 6 -23 53 -50 105 -235 442 -697 763 -1193 830 -104
              14 -373 12 -467 -4z"/>
              </g>
            </svg>
            <svg v-if="v == 2" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">
              <metadata>
              Created by potrace 1.15, written by Peter Selinger 2001-2017
              </metadata>
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M2295 5114 c-16 -2 -66 -9 -110 -15 -174 -23 -510 -106 -525 -130 -4
              -5 -31 -17 -60 -28 -96 -33 -272 -113 -277 -127 -2 -6 -19 -18 -36 -25 -34
              -13 -218 -127 -227 -139 -6 -9 -83 -67 -120 -92 -21 -13 -116 -100 -193 -175
              -53 -52 -171 -181 -185 -203 -25 -38 -83 -114 -92 -120 -12 -9 -126 -193 -139
              -227 -7 -17 -19 -34 -25 -36 -14 -5 -94 -181 -127 -277 -11 -29 -23 -56 -28
              -60 -23 -14 -102 -328 -133 -530 -21 -137 -18 -637 5 -775 32 -192 106 -477
              127 -490 5 -3 16 -29 26 -58 27 -82 113 -271 128 -282 7 -6 25 -34 40 -62 28
              -55 115 -195 127 -203 8 -6 66 -83 91 -120 39 -60 318 -339 378 -378 37 -25
              114 -83 120 -91 8 -12 147 -99 202 -127 28 -14 60 -34 71 -44 19 -18 215 -106
              289 -130 21 -6 40 -15 43 -20 13 -20 274 -89 461 -122 115 -20 162 -23 434
              -23 272 0 319 3 434 23 187 33 448 102 461 122 3 5 22 14 43 20 74 24 270 112
              289 130 11 10 43 30 71 44 55 28 194 115 202 127 6 8 83 66 120 91 60 39 339
              318 378 378 25 37 83 114 91 120 12 8 99 147 127 202 14 28 34 60 44 71 18 19
              106 215 130 289 6 21 15 40 20 43 20 13 89 274 122 461 20 115 23 162 23 434
              0 272 -3 319 -23 434 -33 187 -102 448 -122 461 -5 3 -14 22 -20 43 -26 81
              -112 269 -135 296 -13 16 -27 38 -31 50 -7 22 -122 208 -134 216 -9 6 -67 82
              -92 120 -39 60 -318 339 -378 378 -38 25 -114 83 -120 92 -9 12 -194 127 -218
              134 -12 4 -31 16 -41 27 -19 20 -199 103 -288 133 -29 10 -55 21 -58 26 -13
              20 -267 88 -460 121 -99 18 -169 22 -395 24 -151 2 -288 1 -305 -1z m568 -300
              c65 -8 125 -18 135 -23 9 -5 60 -19 112 -31 164 -39 300 -89 455 -167 156 -79
              170 -87 295 -174 120 -83 247 -193 340 -294 30 -33 72 -77 92 -97 21 -21 38
              -40 38 -43 0 -3 24 -38 53 -78 53 -71 65 -88 111 -157 46 -69 166 -317 199
              -410 19 -52 46 -147 61 -210 15 -63 31 -123 36 -132 21 -38 41 -258 41 -438 0
              -177 -20 -388 -40 -435 -5 -11 -19 -60 -31 -110 -39 -166 -91 -309 -167 -460
              -42 -82 -87 -166 -99 -185 -48 -71 -59 -86 -111 -157 -29 -40 -53 -74 -53 -76
              0 -8 -145 -161 -205 -216 -33 -31 -77 -73 -97 -93 -21 -21 -40 -38 -43 -38 -2
              0 -42 -27 -87 -60 -46 -33 -101 -73 -123 -89 -22 -16 -112 -65 -200 -109 -160
              -81 -302 -133 -470 -172 -49 -12 -99 -26 -110 -31 -30 -14 -155 -29 -303 -38
              -193 -12 -498 9 -567 39 -11 4 -60 18 -110 30 -166 39 -309 91 -460 167 -82
              42 -166 87 -185 99 -71 48 -86 59 -157 111 -40 29 -75 53 -78 53 -3 0 -22 17
              -43 38 -20 20 -64 62 -97 93 -64 59 -205 208 -205 217 0 3 -15 23 -34 46 -18
              23 -51 67 -72 100 -21 32 -48 73 -60 90 -43 64 -165 315 -197 406 -19 52 -46
              147 -61 210 -15 63 -32 124 -37 135 -53 119 -53 745 1 870 5 11 18 63 31 115
              12 52 37 142 56 200 36 110 175 397 225 465 40 55 131 183 144 203 17 27 144
              162 209 222 33 30 76 71 95 90 19 19 42 39 52 45 18 11 141 98 203 143 68 50
              355 189 465 225 58 19 148 44 200 56 52 12 102 26 111 31 25 13 164 32 294 39
              148 9 294 4 448 -15z"/>
              <path d="M1890 4189 c-13 -6 -27 -14 -30 -18 -3 -4 -36 -20 -75 -36 -142 -56
              -290 -119 -295 -125 -3 -4 -73 -34 -156 -68 -83 -33 -178 -75 -211 -91 -33
              -17 -66 -31 -72 -31 -6 0 -11 -8 -11 -19 0 -40 108 -264 124 -258 132 53 251
              104 291 124 47 23 208 92 320 135 27 11 52 23 55 27 3 4 48 25 100 46 52 21
              99 41 104 46 12 11 -95 280 -111 278 -5 0 -19 -5 -33 -10z"/>
              <path d="M3167 4148 c-56 -127 -89 -220 -81 -227 5 -4 29 -15 54 -25 95 -38
              145 -61 158 -73 7 -7 18 -13 23 -13 10 0 87 -31 244 -97 33 -14 101 -45 152
              -69 50 -24 96 -44 101 -44 6 0 40 -13 77 -30 37 -16 69 -30 71 -30 4 0 73 143
              98 204 24 58 21 70 -21 85 -21 8 -40 17 -43 20 -5 7 -96 47 -270 117 -52 21
              -97 41 -100 45 -3 4 -86 40 -185 80 -99 40 -186 79 -193 86 -7 7 -17 13 -22
              13 -5 0 -15 3 -24 6 -11 4 -22 -8 -39 -48z"/>
              <path d="M1605 3388 c-36 -14 -46 -21 -106 -80 -27 -26 -52 -60 -55 -75 -4
              -15 -12 -31 -17 -35 -6 -4 -11 -37 -11 -75 -1 -84 31 -158 96 -220 58 -57 106
              -75 194 -75 89 1 126 16 198 83 45 42 60 63 72 107 20 68 15 172 -11 222 -23
              46 -94 113 -148 139 -48 23 -159 28 -212 9z"/>
              <path d="M3304 3380 c-60 -30 -122 -86 -146 -134 -29 -56 -35 -157 -14 -228
              12 -44 27 -65 72 -107 72 -67 109 -82 198 -83 87 0 131 17 192 74 67 62 99
              135 98 221 0 38 -5 71 -11 75 -5 4 -13 20 -17 35 -6 24 -72 99 -117 133 -53
              39 -189 46 -255 14z"/>
              <path d="M2297 2545 c-49 -8 -125 -24 -170 -36 -90 -24 -259 -87 -267 -99 -3
              -4 -34 -22 -70 -40 -62 -32 -160 -94 -203 -129 -193 -156 -339 -325 -428 -496
              -23 -44 -48 -89 -55 -100 -18 -25 -18 -45 0 -45 8 0 16 -4 18 -10 3 -10 214
              -100 234 -100 6 0 25 31 42 69 17 37 37 75 44 82 8 8 36 46 62 84 65 94 194
              224 286 287 41 28 81 57 88 65 20 20 171 88 263 118 46 15 86 31 89 34 28 35
              398 53 548 26 53 -10 99 -20 102 -24 3 -3 46 -19 95 -35 85 -27 263 -108 275
              -125 3 -4 37 -29 75 -55 95 -65 226 -195 287 -285 28 -41 56 -80 63 -87 7 -7
              27 -44 45 -83 19 -39 39 -71 46 -71 16 0 182 67 227 93 20 11 37 25 37 30 0 4
              -17 39 -38 75 -22 37 -42 75 -45 85 -7 18 -76 123 -106 160 -103 127 -196 223
              -280 285 -20 15 -38 29 -41 33 -10 11 -130 90 -147 96 -10 4 -49 25 -87 46
              -109 61 -243 108 -415 144 -107 22 -457 27 -574 8z"/>
              </g>
            </svg>
            <svg v-if="v == 3" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="1233.000000pt" height="1233.000000pt" viewBox="0 0 1233.000000 1233.000000"
             preserveAspectRatio="xMidYMid meet">
              <metadata>
              Created by potrace 1.15, written by Peter Selinger 2001-2017
              </metadata>
              <g transform="translate(0.000000,1233.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M5765 12324 c-162 -12 -391 -38 -535 -60 -878 -135 -1709 -455 -2460
              -948 -783 -515 -1456 -1226 -1940 -2051 -105 -180 -291 -555 -375 -760 -177
              -426 -316 -932 -389 -1405 -54 -353 -61 -463 -61 -935 0 -472 7 -582 61 -935
              135 -878 455 -1709 948 -2460 515 -783 1226 -1456 2051 -1940 180 -105 555
              -291 760 -375 426 -177 932 -316 1405 -389 353 -54 463 -61 935 -61 472 0 582
              7 935 61 878 135 1709 455 2460 948 783 515 1456 1226 1940 2051 105 180 291
              555 375 760 177 426 316 932 389 1405 54 353 61 463 61 935 0 472 -7 582 -61
              935 -135 878 -455 1709 -948 2460 -515 783 -1226 1456 -2051 1940 -180 105
              -555 291 -760 375 -426 177 -932 317 -1405 389 -330 50 -441 58 -875 61 -225
              1 -432 1 -460 -1z m900 -689 c1018 -98 1953 -457 2772 -1064 242 -179 525
              -430 721 -641 393 -421 673 -824 925 -1330 313 -625 490 -1257 559 -1995 16
              -172 16 -693 0 -875 -65 -725 -245 -1372 -558 -2000 -277 -555 -604 -1009
              -1039 -1445 -436 -435 -890 -762 -1445 -1039 -628 -313 -1277 -494 -2000 -557
              -187 -17 -683 -17 -870 0 -1156 101 -2201 529 -3079 1261 -796 662 -1396 1551
              -1714 2535 -129 401 -210 804 -248 1245 -17 187 -17 683 0 870 63 723 244
              1372 557 2000 277 555 604 1009 1039 1445 781 781 1764 1304 2845 1514 245 48
              438 71 800 95 115 8 582 -4 735 -19z"/>
              <path d="M4000 8195 c-81 -18 -210 -81 -272 -133 -242 -202 -317 -547 -178
              -822 68 -135 175 -239 311 -305 107 -51 200 -69 328 -62 180 9 309 66 436 192
              80 80 125 151 163 257 24 67 27 86 27 218 0 132 -3 151 -27 218 -73 202 -218
              346 -417 415 -71 25 -105 31 -196 33 -71 2 -133 -2 -175 -11z"/>
              <path d="M8112 8191 c-190 -47 -351 -178 -435 -352 -54 -113 -71 -184 -70
              -304 1 -187 64 -336 198 -471 131 -131 283 -193 476 -194 272 0 521 170 620
              425 101 261 34 548 -174 741 -161 149 -397 208 -615 155z"/>
              <path d="M2940 4150 l0 -340 3220 0 3220 0 0 340 0 340 -3220 0 -3220 0 0
              -340z"/>
              </g>
            </svg>
            <svg v-if="v == 4" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">
              <metadata>
              Created by potrace 1.15, written by Peter Selinger 2001-2017
              </metadata>
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M2240 5105 c-462 -60 -856 -222 -1240 -509 -122 -91 -385 -354 -476
              -476 -268 -358 -434 -747 -501 -1170 -26 -164 -26 -616 0 -780 82 -519 319
              -998 680 -1376 394 -412 874 -667 1441 -766 174 -30 543 -37 727 -13 1188 149
              2110 1084 2239 2272 16 145 8 533 -14 667 -184 1134 -1088 2011 -2216 2151
              -150 18 -501 18 -640 0z m505 -275 c565 -52 1064 -290 1450 -691 347 -360 558
              -797 626 -1299 19 -139 17 -450 -5 -590 -87 -577 -364 -1072 -805 -1441 -318
              -266 -712 -441 -1136 -505 -163 -24 -466 -25 -625 -1 -412 63 -785 224 -1104
              475 -107 84 -323 305 -402 412 -238 321 -381 665 -440 1055 -25 165 -25 466 0
              630 114 758 582 1400 1261 1730 377 183 786 261 1180 225z"/>
              <path d="M1640 3401 c-48 -15 -87 -40 -123 -80 -53 -59 -70 -104 -70 -191 -1
              -62 4 -84 26 -126 106 -205 402 -204 503 2 73 148 -2 337 -154 388 -48 17
              -139 20 -182 7z"/>
              <path d="M3334 3394 c-149 -54 -221 -243 -149 -389 100 -205 397 -206 502 -1
              22 42 27 64 27 126 0 46 -6 89 -16 111 -22 51 -84 116 -132 141 -53 28 -174
              34 -232 12z"/>
              <path d="M1332 1949 c-62 -40 -112 -75 -112 -79 0 -17 104 -152 174 -227 223
              -237 521 -404 844 -474 141 -30 400 -37 551 -15 349 52 689 228 930 481 71 75
              181 216 181 233 0 11 -217 152 -233 151 -7 0 -34 -32 -62 -70 -27 -39 -89
              -108 -137 -154 -395 -378 -960 -475 -1457 -249 -175 80 -390 255 -496 403 -27
              38 -55 70 -60 70 -6 1 -61 -31 -123 -70z"/>
              </g>
            </svg>
            <svg v-if="v == 5" version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">
              <metadata>
              Created by potrace 1.15, written by Peter Selinger 2001-2017
              </metadata>
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M2255 5108 c-449 -50 -923 -247 -1288 -535 -103 -81 -316 -293 -402
              -399 -234 -289 -407 -638 -495 -996 -57 -233 -65 -308 -65 -618 0 -310 8 -385
              65 -618 67 -271 197 -569 346 -792 292 -436 715 -784 1181 -969 322 -128 605
              -181 963 -181 312 0 537 36 823 130 375 125 692 323 988 619 162 162 282 312
              384 481 181 296 307 660 350 1010 20 163 20 477 0 640 -57 457 -246 910 -532
              1273 -73 93 -327 347 -420 420 -364 287 -815 475 -1273 532 -137 17 -489 19
              -625 3z m660 -327 c369 -63 694 -201 995 -424 122 -91 356 -325 447 -447 181
              -245 303 -497 377 -781 55 -211 70 -334 70 -569 0 -235 -15 -358 -70 -569 -74
              -284 -196 -537 -378 -781 -90 -122 -324 -356 -446 -446 -306 -228 -641 -369
              -1025 -431 -153 -24 -486 -25 -645 0 -380 59 -723 202 -1030 431 -123 91 -357
              325 -447 446 -227 307 -368 642 -430 1025 -24 153 -24 497 0 650 78 481 282
              892 611 1233 370 382 831 612 1361 677 154 20 454 12 610 -14z"/>
              <path d="M1448 3420 c-129 -23 -289 -106 -352 -181 -56 -66 -58 -153 -5 -214
              64 -72 148 -72 241 0 75 59 141 85 218 85 80 0 143 -23 214 -77 32 -25 72 -50
              88 -55 66 -23 152 12 184 75 23 44 21 119 -5 160 -21 34 -92 94 -161 135 -102
              60 -301 95 -422 72z"/>
              <path d="M3453 3420 c-142 -26 -312 -123 -365 -208 -25 -40 -27 -115 -4 -159
              32 -63 118 -98 184 -75 16 5 56 30 88 55 71 54 134 77 214 77 77 0 143 -26
              218 -85 93 -72 177 -72 241 0 53 61 51 148 -5 214 -44 52 -169 129 -256 156
              -92 29 -231 40 -315 25z"/>
              <path d="M1130 2431 c-20 -10 -44 -36 -58 -62 -22 -41 -23 -51 -17 -154 23
              -383 169 -710 439 -981 310 -309 683 -456 1121 -441 402 14 723 155 1011 442
              270 270 416 595 439 980 6 103 5 113 -17 154 -14 26 -38 52 -58 62 -33 18 -90
              19 -1430 19 -1340 0 -1397 -1 -1430 -19z m2605 -338 c-52 -346 -311 -695 -638
              -860 -179 -89 -338 -127 -537 -127 -199 0 -358 38 -537 127 -327 165 -586 514
              -638 860 l-7 47 1182 0 1182 0 -7 -47z"/>
              </g>
            </svg>
          </span>
        </label>
      </div>
    </div>
    <div class="RatingFaces__Labels">
      <p>{{question.question.rankingLeftLabel}}</p>
      <p>{{question.question.rankingRightLabel}}</p>
    </div>
    <textarea type="text"
      class="RatingFaces--AddTxt"
      :placeholder="$parent.translationMethod('put_more_info_ranking')"
      v-show="showText"
      v-model="text"
      v-on:keyup="addText($event)"></textarea>
  </div>
</template>

<script>
export default {
  name: 'RatinFaces',

  props: {
    question:{
      type: Object,
      default: null
    },
  },
  data(){
    return{
      answer:undefined,
      text:'',
      showText:false,
    }
  },
  methods:{
    addText(event){
      this.text = event.target.value
      this.sendAnswers(null)
    },
    sendAnswers(value){
      if(this.question.question.questionAddtxt && this.text.length < 2) {
        this.showText = true
        this.$emit('send-answers', {answer:{val:this.answer,text:this.text},question:this.question, answerValid: false})
      }
      else if(this.question.question.questionAddtxt) this.$emit('send-answers', {answer:{val:this.answer,text:this.text},question:this.question, answerValid: true})
      else this.$emit('send-answers', {answer:{val:value},question:this.question, answerValid: true})
    },
    isIE11(){
      return !!window.MSInputMethodContext && !!document.documentMode;
    },
  },
  async mounted(){
    if (this.question.answer) {
      this.answer = this.question.answer.val
      this.$emit('send-answers', {answer:{val:this.answer},question:this.question, answerValid: true})
    }
  }
}
</script>

<style lang="scss" scoped>
.IE11{
  input {
    &:checked + label, &:hover + label{
      span{
        &::before{
          border-bottom: 10px solid var(--main-color)!important;
          left:30%;
        }
      }
    }
  }
}
.RatingFaces{
  &--AddTxt{
    outline: none;
    width: 100%;
    margin-bottom:10px;
    border: none;
    border-bottom: 1px solid #aeaeae;
    animation: fadeIn 0.2s;
    resize: none;
  }
  &__Icons{
    display:flex;
    justify-content: space-between;
    max-width: 400px;
  }
  &__Labels{
    display:flex;
    justify-content: space-between;
    max-width: 400px;
    margin: 10px 0;
    p:nth-child(2){
      text-align: end;
    }
  }
  &__Option{
    margin-right: 10px;
    input {
      display: none;
      &:checked + label, &:hover + label{
        span{
          position: relative;
          g{
            fill:var(--main-color);
          }
          &::before{
            content: "";
            bottom: 0;
            width: 0;
            height: 0;
            position: absolute;
            border-bottom: 10px solid;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            margin-bottom: -15px;
            color: var(--main-color);
          }
        }
      }
    }
    label{
      display: flex;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      span{
        width: 40px;
        height: 40px;
        filter: opacity(0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
