<template lang="html">
  <div class="Ranking" :class="{'Ranking--NSNCType':question.question.ninetyNineOption}">
    <div class="Ranking--Options">
      <div class="Ranking--Option" :class="{'selected': answer == v, 'Ranking--Stars':question.question.rankingType == 'STARS'}" v-for="v in rankingMax" v-on:click="sendAnswers(v)">
        <span v-if="question.question.rankingType == 'NUMBERS'">
          {{v}}
        </span>
        <span v-if="question.question.rankingType == 'STARS'">
          <i>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.72759 30C6.3754 30 6.07353 29.8953 5.77165 29.6859C5.26851 29.3194 5.01694 28.6387 5.16788 27.9581L7.07978 19.1099L0.539068 13.1414C0.0359355 12.7225 -0.115001 11.9895 0.0862518 11.3613C0.287505 10.733 0.840948 10.2618 1.44471 10.2094L10.1489 9.37173L13.5702 0.994764C13.8218 0.366492 14.3752 0 15.0293 0C15.6833 0 16.2368 0.418848 16.4884 0.994764L19.9097 9.37173L28.5635 10.2094C29.2176 10.2618 29.7207 10.733 29.922 11.3613C30.1232 11.9895 29.922 12.6702 29.4692 13.1414L22.9284 19.1099L24.8403 27.9581C24.9913 28.5864 24.7397 29.267 24.2366 29.6859C23.7335 30.0524 23.0291 30.1047 22.4756 29.7906L15.0293 25.0785L7.53261 29.7382C7.28104 29.8953 7.02947 30 6.72759 30Z" :fill="v <= answer && answer != 99 ? 'url(#rankingGradient)' : '#c3c3c3'"/>
              <defs>
              <linearGradient id="rankingGradient" x1="1.11759e-07" y1="15" x2="30" y2="15" gradientUnits="userSpaceOnUse">
                <stop stop-color="var(--main-color)"/>
                <stop offset="1" stop-color="var(--secondary-color)"/>
              </linearGradient>
              </defs>
            </svg>
          </i>
          <p>{{v}}</p>
        </span>
      </div>
    </div>
    <div v-if="question.question.ninetyNineOption" v-on:click="sendAnswers(99)">
      <div class="Ranking--Option Ranking--NSNC" v-if="question.question.rankingType == 'NUMBERS'" :class="{'selected': answer == 99}">
        {{$parent.translationMethod('question_not_answer')}}
      </div>
      <div v-if="question.question.rankingType == 'STARS'" class="Ranking--Option Ranking--Stars Stars99" :class="{'selected': answer == 99}">
        <i class="fa fa-circle"></i>
        <p>{{$parent.translationMethod('question_not_answer')}}</p>
      </div>
    </div>
    <div class="Ranking--Labels">
      <p>{{question.question.rankingLeftLabel}}</p>
      <p>{{question.question.rankingRightLabel}}</p>
    </div>
    <textarea type="text"
      class="Ranking--AddTxt"
      :placeholder="$parent.translationMethod('put_more_info_ranking')"
      v-show="showText"
      v-model="text"
      v-on:keyup="addText($event)"></textarea>
  </div>

</template>

<script>
import { getCurrentInstance } from 'vue';
export default {
  name: 'Ranking',

  props: {
    question:{
      type: Object,
      default: null
    },
  },
  data(){
    return{
      answer:undefined,
      text:'',
      showText:false,
      _uid:getCurrentInstance().uid
    }
  },
  computed: {
    rankingMax(){
      let ranking = [];
      for (let i = this.question.question.rankingMin; i <= this.question.question.rankingMax; i++) {
        ranking.push(i)
      }
      return ranking;
    }
  },
  methods:{
    addText(event){
      this.text = event.target.value
      this.sendAnswers(null)
    },
    sendAnswers(value){
      if(value != null) this.answer = value
      if(this.question.question.questionAddtxt && this.text.length < 2) {
        this.showText = true
        this.$emit('send-answers', {answer:{val:this.answer,text:this.text},question:this.question, answerValid: false})
      }
      else if(this.question.question.questionAddtxt) this.$emit('send-answers', {answer:{val:this.answer,text:this.text},question:this.question, answerValid: true})
      else this.$emit('send-answers', {answer:{val:value},question:this.question, answerValid: true})
    },
  },
  async mounted(){
    if (this.question.answer) {
      this.answer = this.question.answer.val
      this.$emit('send-answers', {answer:{val:this.answer},question:this.question, answerValid: true})
    }
  }
}
</script>

<style lang="scss" scoped>
.Ranking{
  display: grid;
  gap: 10px;
  grid-template: 
            "a a a a a c"
            "b b b b b e"
            "b b b b b e"
            "d d d d d d";
  &--Options{
    display: flex;
    justify-content: space-between;
    grid-area: a;
  }
  &--Option{
    position: relative;
    font-family: 'Oswald', sans-serif;
    border: 1px solid #aeaeae;
    font-size: 23px;
    padding: 0 10px;
    cursor: pointer;
    &.selected, &:hover{
      background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
      color: white;
      transition: 0.5s;
      &::before{
            content: "";
            bottom: 0;
            width: 0;
            height: 0;
            position: absolute;
            border-bottom: 10px solid var(--main-color);
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            margin-bottom: -15px;
          }
    }
  }
  &--NSNC{
    grid-area: c;
    padding: 0px 2px;
  }
  &--Labels{
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    margin-top: 6px;
    justify-content: space-between;
    grid-area: b;
    p:nth-child(2){
      text-align: end;
    }
  }
  &--AddTxt{
    outline: none;
    width: 100%;
    margin-bottom:10px;
    border: none;
    border-bottom: 1px solid #aeaeae;
    animation: fadeIn 0.2s;
    resize: none;
    grid-area: d;
  }
  &--Stars{
    border: none;
    padding:0;
    &.selected, &:hover{
      background: transparent;
      color: var(--main-color);
      &:before{
        border: none;
      }
    }
    span{
      display:flex;
      flex-direction: column;
      align-items: center;
    }
    p{
      font-size:1.5vw;
    }
    i{
      font-size: 3vw;
    }
  }
  &--NSNCType{
    @media only screen and ( max-width: 957px ) {
      .Ranking--Option{
        font-size: 17px;
        padding: 0 5px;
      }
      .Ranking--Stars{
        padding: 0!important;
      }
    }
    @media only screen and ( max-width: 325px ) {
        gap: 0px;
        .Ranking--Option{
          font-size: 12px;
        }
    }
  }
  @media only screen and ( max-width: 865px ) {
    &--Option{
      font-size: 15px;
      padding: 0 5px;
    }
    &--Stars{
      padding: 0!important;
    }
  }
  @media only screen and ( max-width: 769px ) {
    &--Option{
      font-size: 22px;
      padding: 0 5px;
    }
    &--Labels{
      font-size: 13px;
    }
    &--Stars{
        p{
          font-size: 5vw!important;
        }
        i{
          font-size: 6vw!important;
        }
      }
  }
  @media only screen and ( max-width: 420px ) {
    &--Stars{
        svg{
          width: 20px;
        }
      }
  }
}
.Stars99{
  align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  i{
    margin-top: 10px;
    margin-bottom: 3px;
  }
}
</style>
