<template lang="html">
  <div class="Label">
  </div>
</template>

<script>
export default {
  name: 'Label',

  props: {
    question:{
        type: Object,
        default: null
    },
  },

  async mounted(){
    this.$emit('send-answers', {answer:{val:0},question:this.question, answerValid: true})
  }
}
</script>

<style lang="scss">
</style>
