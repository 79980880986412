<template lang="html">
  <div class="Textarea">
    <textarea v-on:keyup="addAnswer($event)" v-model="answer" :disabled="notResponse" :placeholder="$parent.translationMethod('Imput_textanswer')"></textarea>
    <div class="Textarea__NotResponse" v-if="question.question.notResponse">
      <div>
        <input type="checkbox" :id="'answer_'+question.question.order" :name="'answer_'+question.question.order" v-on:change="addAnswer()" v-model="notResponse">
        <label :for="'answer_'+question.question.order">
          <span>
          </span>
          <i class="fa fa-check"></i>
          {{question.question.notResponseText}}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Textarea',

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      answer:null,
      notResponse:false,
    }
  },
  methods:{
    addAnswer(e){
      this.answer = e != undefined ? e.target.value : null
      let answerValid = false
      let answer = {}

      if (this.answer != null && this.answer.length >= 10 && (/\S/.test(this.answer))) {
        answer.text = this.answer
        answerValid = true
      }
      if (this.notResponse) {
        answer.text= this.question.question.notResponseText
        answer.nsnc = true
        answerValid = true
      }
      if (this.question.question.textEmpty){
        answerValid = true
      }
      this.$emit('send-answers', {answer:answer,question:this.question, answerValid:answerValid})
    },
  },
  async mounted(){
    if (this.question.answer) {
      this.answer = this.question.answer.text
      if (this.answer == this.question.question.notResponseText) this.notResponse = true
      this.addAnswer()
    }
    if(this.question.question.textEmpty){
      this.$emit('send-answers', {answer:{text:""},question:this.question, answerValid:true})
    }
  }
}
</script>

<style lang="scss" scoped>
.Textarea{
  textarea{
    border-radius: 5px;
    color: #a6a6a6;
    width: 100%;
    height:100%;
    border:1px solid #dfdfdf;
    outline:none;
    background: white;
    padding: 10px;
    resize:none;
    min-height: 100px;
    &::placeholder{
      color: #cdcdcd;
    }
  }
  &__NotResponse{
    div{
      width: 100%;
      display: flex;
      input {
        display: none;
        &:checked + label{
          color: var(--main-color);
          font-weight: 700;
          span{
            display:none;
          }
          i{
            display:block;
          }
        }
      }
      label{
        display: flex;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        i{
          display: none;
          font-size:25px;
          height: 26px;
          margin-right: 8px;
          background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        span{
          display: flex;
          width: 20px;
          min-width: 20px;
          height: 20px;
          margin: 3px 10px 3px 3px;
          padding:4px;
          vertical-align: middle;
          border: 1px solid #aeaeae;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
