<template lang="html">
  <div class="Sorting">
    <draggable class="Sorting__Container" v-model="question.question.values" item-key="id" @end="dragEnd()">
      <template #item="{element,index}">
        <div class="Sorting__Element">
          <p class="Sorting__Order" v-if="index < 9">0{{index + 1}}</p>
          <p class="Sorting__Order" v-if="index > 8">{{index + 1}}</p>
          <div class="Sorting__Value">
            <p style="margin:0;" v-html="element.value"></p>
            <div class="Sorting__Value--icon">
              <i class="fas fa-long-arrow-alt-up"></i>
              <i class="fas fa-long-arrow-alt-down"></i>
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'Sorting',
  components:{
    draggable
  },
  props: {
    question:{
      type: Object,
      default: null
    },
  },
  data() {
    return {
      answers: [],
    }
  },

  methods: {
    dragEnd(){
      this.addAnswers()
    },
    addAnswers(){
      this.answers = []
      for (let i in this.question.question.values) {
        // this.answers.push(this.question.question.values[i].id+1+'-'+i)
        this.answers.push((parseInt(i)+1)+'-'+ this.question.question.values[i].id)
      }
      this.answers.sort()
      this.$emit('send-answers', {answer:{sortedVals:this.answers},question:this.question, answerValid: true})
    },
  },

  async mounted(){
    if (this.question.answer) {
      let answers = []
      for (let i in this.question.question.values) {
        answers[this.question.answer.sortedVals[i].split("-")[1]] = this.question.question.values[i]
      }
      this.question.question.values = answers
    }
    this.addAnswers()
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

.Sorting{
  p{
    margin:0;
  }
  &__Element{
    display: grid;
    grid-template-columns: 30px calc(100% - 30px);
    cursor: pointer;
    margin-bottom: 5px;
  }
  &__Order{
    font-family: 'Oswald', sans-serif;
    font-size: 1.6vw;
    font-weight: 500;
    color: black;
    opacity: 0.6;
    padding: 10px 15px 10px 0;
  }
  &__Value{
    display:flex;
    justify-content: space-between;
    box-shadow: 0px 1px 6.72px 0.28px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 100%;
    background: white;
    p{
      color: #666666;
      text-align: left;
    }
    &--icon{
      color:var(--main-color);
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  @media only screen and ( max-width: 769px ) {
    &__Element{
      box-shadow: 0px 1px 6.72px 0.28px rgba(0, 0, 0, 0.1);
      background: white;
      margin: 10px 0;
    }
    &__Order{
      font-size:initial;
      padding: 10px 15px;
      font-size: 19px;
    }
    &__Value{
      box-shadow:none;
      font-size: 17px;
    }
  }
}
</style>
