
  import {defineComponent} from 'vue';
  import '@fortawesome/fontawesome-free/css/all.css';

  export default defineComponent({
    name: 'Timer',
    props: {
      value: {
        type: Number,
        default: null
      },
      stop: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        minutes: 0,
        seconds: 0,
        intervalId: null
      }
    },
    methods: {
      startTimer() {
        this.minutes = Math.floor(this.value / 60);
        this.seconds = this.value % 60;
        this.addZeroToNumber();
        this.intervalId = setInterval(() => {
          if (this.seconds == 0) {
            if (this.minutes == 0) {
              this.$emit('set-timer-finished')
              clearInterval(this.intervalId);
            } else {
              this.minutes--;
              this.seconds = 59;
            }
          } else {
            this.seconds--;
          }
          this.addZeroToNumber();
        }, 1000);
      },
      addZeroToNumber() {
        this.minutes = this.minutes.toString().padStart(2, '0');
        this.seconds = this.seconds.toString().padStart(2, '0');
      },
      stopTimer() {
        clearInterval(this.intervalId);
      }
    },
    mounted() {
      this.startTimer();
    },
    watch: {
      stop(value) {
        if (value) this.stopTimer();
      }
    }
  })

