<template lang="html">
  <div class="MatrixBasic">
    <div class="MatrixBasic__Container" v-for="(v, index) in question.question.values">
      <div class="MatrixBasic--Text" v-html="v.value">
      </div>
      <div class="MatrixBasic__Values">
        <div class="MatrixBasic__Option" v-for="(rankVal,i) in (question.question.rankingMax+1)" v-on:click="$parent.addAnswer(v.id,i,index)" v-show="i <= question.question.rankingMax && i >= question.question.rankingMin">
          <input type="radio" :id="'answer_'+question.question.order+'_'+v.id+'_'+i" :name="'answer_'+question.question.order+'_'+v.id +'_'+i" :value="v.id +'-'+i" v-model="$parent.answers[index]">
          <label :for="'answer_'+question.question.order + '_' + v.id+'_'+i" :class="{'starsLabel':question.question.matrixVisualization == 'STARS'}">
            <span v-if="question.question.matrixVisualization == 'STARS'" :class="{'selectedStar':$parent.isSelected(index,i)}"><i class="icon-wat_star"></i></span>
            <span v-else>{{i}}</span>
          </label>
        </div>
        <div class="MatrixBasic__Option" v-on:click="$parent.addAnswer(v.id,99,index)" v-if="question.question.ninetyNineOption">
          <input type="radio" :id="'answer_'+question.question.order+'_'+v.id+'_'+99" :name="'answer_'+question.question.order+'_'+v.id +'_'+99" :value="v.id +'-'+99" v-model="$parent.answers[index]">
          <label :for="'answer_'+question.question.order + '_' + v.id+'_'+99">
            <span>
              {{$t('question_not_answer')}}
            </span>
          </label>
        </div>
      </div>
      <textarea type="text"
        class="MatrixBasic--AddTxt"
        :placeholder="$t('put_more_info_ranking')"
        v-show="v.addtxt && $parent.answers[index] != undefined"
        v-model="$parent.addedTexts[v.id]"
        v-on:keyup="$parent.addText($event,v)"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatrixBasic',

  props: {
    question:Object,
  },

  data() {
    return {
      showText:false,
    }
  }
}
</script>

<style lang="scss" scoped>
.MatrixBasic{
  &--AddTxt{
    outline: none;
    width: 100%;
    margin-bottom:10px;
    border: none;
    border-bottom: 1px solid #aeaeae;
    animation: fadeIn 0.2s;
    resize: none;
  }
  &--Text{
    margin-top: 10px;
    display: flex;
    text-align: initial;
  }
  &__Values{
    display: flex;
  }
  &__Option{
    font-family: 'Oswald', sans-serif;
    font-size: 2.556vw;
    margin: 15px 5px 5px 0;
    input {
      display: none;
      &:checked + .starsLabel, &:hover + .starsLabel{
        span{
          background: transparent!important;
        }
        i:before{
          background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &:checked + label, &:hover + label{
        color: var(--text-color);
        span{
          background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
          color: white;
          animation: fadeIn 0.2s;
          position: relative;
          &::before{
            content: "";
            bottom: 0;
            width: 0;
            height: 0;
            position: absolute;
            border-bottom: 10px solid var(--main-color);
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            margin-bottom: -15px;
          }
        }
      }
    }
    label{
      display: flex;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      &.starsLabel{
        span{
          border: none;
          padding:0px 10px
        }
      }
      span{
        border: 1px solid #aeaeae;
        line-height: 1;
        padding: 6px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .selectedStar{
      i:before{
        background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  @media only screen and ( max-width: 769px ) {
    &__Option{
      font-size: 20px;
      margin: 15px 3px 5px 0;
      label{
        span{
          padding: 4px 6px!important;
        }
      }
    }
    &__Values{
      justify-content: space-between;
    }
  }
  @media only screen and ( max-width: 420px ) {
    &__Option{
      margin-right: 0;
      font-size: 5vw;
      label{
        span{
          padding: 2px 3px!important;
        }
      }
    }
  }
}
</style>
