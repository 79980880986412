<template lang="html">
    <div class="MultipleAnswers">
        <div class="Matrix__Radio">
            <div class="Matrix__Radio__Values">
                <div class="Matrix__Radio__Values--scroll" id="matrix-scroller">
                    <div :id="'matrix-scroller_' + _uid" class="scrollFalse">
                        <p v-for="(txt, index) in radioOptionTexts" :class="{ 'selected': current == index }"
                            :id="'p_' + _uid + '-' + index" v-html="txt">
                        </p>
                    </div>
                </div>
            </div>
            <div class="Matrix__Radio__Options" v-for="(txt, index) in radioOptionTexts" v-show="current == index">
                <div v-for="(v, i) in values">
                    <input type="checkbox" :id="'answer_' + _uid + '_' + v.id + '_' + i + index"
                        :name="'answer_' + _uid + '_' + v.id + '_' + i + index" :value="v.id" :checked="v.checked"
                        @click="addAnswer(v)" :disabled="disableValues(v)">
                    <label :for="'answer_' + _uid + '_' + v.id + '_' + i + index">
                        <span>
                            {{ v.value }}
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <button type="button" name="button" @click="next()" :disabled="answers.length == 0">{{
            $t('Button_next')}}</button>
    </div>
</template>

<script>
import { getCurrentInstance } from 'vue';
export default {
    name: 'MultipleAnswers',

    props: {
        questions: Array,
        radioOptionTexts: Array,
    },
    data() {
        return {
            answers: [],
            current: 0,
            percents: [],
            showText: false,
            _uid: getCurrentInstance().uid,
            values: [], // Nueva propiedad en data
        };
    },
    methods: {
        addAnswer(value) {
            if (!this.answers.includes(value.id) && value.exclusive) {
                this.values.forEach((v) => {
                    v.checked = false;
                });
                value.checked = true;
                this.answers = [value.id];
                return;
            }
            if (this.answers.includes(value.id)) {
                value.checked = false;
                this.answers.splice(this.answers.indexOf(value.id), 1);
                return;
            }
            value.checked = true;
            this.answers.push(value.id);
        },
        disableValues(value) {
            for (let v of this.values) {
                if (this.answers.includes(v.id) && v.exclusive && value.id != v.id) {
                    if (this.answers.includes(value.id)) {
                        value.checked = false;
                        this.answers.splice(this.answers.indexOf(value.id), 1);
                    }
                    return true;
                }
            }
            if (
                this.answers.length == this.questions[this.current].question.multiselectionMax &&
                !this.answers.includes(value.id)
            ) {
                return true;
            }
            return false;
        },
        next() {
            this.$emit('send-answers', {
                answer: { multipleVals: this.answers },
                question: this.questions[this.current],
                answerValid: true,
            });
            this.answers = [];
            if (this.current == this.radioOptionTexts.length - 1) return;
            this.current++;
            // Reinicia values para la nueva pregunta
            this.values = this.questions[this.current].question.values.map((v) => ({
                ...v,
                checked: false,
            }));
            this.setLeftPosition();
        },
        isAnswered(i) {
            return this.answers.length > 0;
        },
        setLeftPosition() {
            const leftStyle = -100 * this.current + '%';
            document.getElementById('matrix-scroller_' + this._uid).style.left = leftStyle;
        },
    },
    mounted() {
        // Inicializa values para la primera pregunta
        this.values = this.questions[this.current].question.values.map((v) => ({
            ...v,
            checked: false,
        }));
    },
};
</script>

<!-- Mantén tu estilo CSS aquí -->


<style lang="scss" scoped>
button{
  display: block;
  font-family: 'Bariol';
  font-weight: 600;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color:white;
  margin: 20px 0;
  background: var(--button-color);
  &:disabled{
    cursor:not-allowed;
    opacity: 0.6;
  }
}
.Matrix{
  &__Radio{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__Values{
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--scroll{
        overflow: hidden;
        border-radius: 3px;
        border: 1px solid lightgray;
        padding: 5px;
        margin:5px;
        width: 100%;
        &::-webkit-scrollbar-track{
          background-color: #e6e6e6;
        }
        &::-webkit-scrollbar {
          background:linear-gradient(to right,var(--main-color) , var(--secondary-color)); ;
          height: 15px;
          width: 10px;
          cursor: pointer;
        }
        &::-webkit-scrollbar-thumb{
          border-radius: 10px;
          background-image: -webkit-gradient(linear,40% 0%,75% 84%,from(var(--main-color)),to(var(--secondary-color)),color-stop(.6,var(--secondary-color)));
        }
        p{
          margin: auto;
          padding: 10px;
          width: fit-content;
          max-width: 350px;
          min-height: 72%;
          background-color: #fff;
          border-radius: 8px;
          text-align: center;
          position: relative;
          opacity: 0.4;
          cursor: pointer;
          transition: left 1s, opacity 1s;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          &.selected{
            color: var(--main-color);
            opacity:1;
            z-index: 1;
          }
          i{
            color: var(--main-color);
          }
          span{
            border-radius: 50%;
            background-color: #dcdee1;
            width: fit-content;
            padding: 0 7px;
            height: 25px;
            font-size: 11px;
            position: absolute;
            top: -10px;
            color:black;
            display: flex;
            align-items: center;
          }
        }
        .scrollFalse{
          width:100%;
          min-height: 200px;
          position:relative;
          display: flex;
          display: grid;
          align-items: center;
          grid-template-columns: repeat(100, minmax(100%, 1fr) );
          transition: left 1s, opacity 1s;
        }
      }
    }
    &__Options{
      div{
        font-family: 'Oswald', sans-serif;
        margin: 15px 5px 5px 0;
        input {
          display: none;
          &:checked + label, &:hover + label{
            color: var(--text-color);
            span{
              background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
              color: white;
              animation: fadeIn 0.2s;
              position: relative;
            }
          }
          &:disabled + label{
            span{
              cursor: not-allowed;
              background: transparent;
              color: #666666;
              opacity: 0.6;
            }
          }
        }
        label{
          display: flex;
          align-items: center;
          font-weight: 500;
          cursor: pointer;
          span{
            border: 1px solid #aeaeae;
            width: 100%;
            line-height: 1;
            padding: 6px 14px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
