<template>
  <div class="Selection" :class="{'column':question.question.values.length < 13}">
    <div class="Selection__Container" :style="{'order':v.order}" v-for="(v,index) in getValues('ALL',null)">
      <div :id="`Selection__Option${index}`" class="Selection__Option" v-on:click="addAnswer(v)">
        <input :id="'answer_'+question.question.order + '_' + v.id" type="radio" :name="'answer_'+question.question.order" :value="v.id" v-model="answer">
        <label :for="'answer_'+question.question.order + '_' + v.id">
          <span>
            <i></i>
          </span>
          <p style="margin:0;" v-html="v.value"></p>
        </label>
      </div>
      <textarea type="text"
        class="Selection__Addtxt"
        :placeholder="v.addTxtPlaceholder!=undefined && v.addTxtPlaceholder!=null && v.addTxtPlaceholder != '' ? v.addTxtPlaceholder : $parent.translationMethod('put_more_info')"
        v-if="v.addtxt && answer == v.id"
        v-model="addedText"
        v-on:keyup="addText($event)"></textarea>
    </div>
    <div class="Selection__Container Selection--Group" :class="{'selected':selectedGroup == i}" v-for="(g,i) in question.question.groups" :style="{'order':question.question.values.length+i}">
      <div class="Selection--GroupOption" v-on:click="openGroup(i)">
        <i v-show="selectedGroup == i">-</i>
        <i v-show="selectedGroup != i">+</i>
        {{g.groupName}}
      </div>
      <div class="Selection--GroupValues" :class="{'opened':selectedGroup == i}">
        <div :style="{'order':v.order}" v-for="(v,i) in getValues(null, g.values)">
          <div class="Selection__Option" v-on:click="addAnswer(v)">
            <input :id="'answer_'+question.question.order + '_' + v.id" type="radio" :name="'answer_'+question.question.order" :value="v.id" v-model="answer">
            <label :for="'answer_'+question.question.order + '_' + v.id">
              <span>
                <i></i>
              </span>
              <p style="margin:0;" v-html="v.value"></p>
            </label>
          </div>
          <textarea type="text"
            class="Selection__Addtxt"
            :placeholder="v.addTxtPlaceholder!=undefined && v.addTxtPlaceholder!=null && v.addTxtPlaceholder != '' ? v.addTxtPlaceholder : $parent.translationMethod('put_more_info')"
            v-if="v.addtxt && answer == v.id"
            v-model="addedText"
            v-on:keyup="addText($event)"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Selection',

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      answer:undefined,
      addedText:"",
      selectedGroup:null,
    }
  },

  methods: {
    openGroup(groupIndex){
      this.selectedGroup = this.selectedGroup == groupIndex ? null : groupIndex
    },
    isAddedToGroup(valueId){
      let isAdded = false
      if(this.question.question.groups == undefined || this.question.question.groups == null || this.question.question.groups.length == 0) return isAdded
      for(let g of this.question.question.groups){
        if(g.values.includes(valueId)) isAdded = true
      }
      return isAdded
    },
    getValues(type, groupValues){
      let values = []
      if(type == "ALL"){
        for(let v of this.question.question.values){
          if(!this.isAddedToGroup(v.id)) values.push(v)
        }
      }
      else{
        for(let v of this.question.question.values){
          if(groupValues.includes(v.id)) values.push(v)
        }
      }
      return values
    },
    addAnswer(value){
      this.answer = value.id
      if (value.addtxt){
        this.addText()
        return
      }
      else this.$emit('send-answers', {answer:{val:this.answer},question:this.question, answerValid:true})
    },
    addText(e){
      this.addedText = e != undefined ? e.target.value : ""
      let answerValid = true
      if (this.addedText == undefined || this.addedText.length < 1) answerValid = false
      this.$emit('send-answers', {answer:{val:this.answer,text:this.addedText},question:this.question, answerValid:answerValid})
    },
  },
  async mounted(){
    if (this.question.answer){
      this.answer = this.question.answer.val
      this.addedText = this.question.answer.text
      if(this.addedText) this.$emit('send-answers', {answer:{val:this.answer,text:this.addedText},question:this.question, answerValid:true})
      else this.$emit('send-answers', {answer:{val:this.answer},question:this.question, answerValid:true})
    }
  },
}
</script>

<style lang="scss" scoped>
.Selection{
  display: flex;
  flex-wrap: wrap;
  &.column{
    flex-direction: column;
    .Selection__Container{
      order:0!important;
      width: 100%;
    }
  }
  &__Container{
    position:relative;
    cursor: pointer;
    width: 50%;
  }
  &__Option{
    width: 100%;
    display: flex;
    input {
    	display: none;
      &:checked + label{
        color: var(--main-color);
        i{
          transition: 0.1s;
          background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
          transform: scale(1);
        }
      }
    }
    label{
      display: flex;
      font-weight: 500;
      cursor: pointer;
      span{
        display: flex;
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin: 1px 10px 3px 3px;
        padding:4px;
        vertical-align: middle;
        border-radius: 50%;
        border: 1px solid #aeaeae;
        i{
          height: 100%;
          width: 100%;
          border-radius: 50%;
          transform: scale(0);
        }
      }
    }
  }
  &__Addtxt{
    outline: none;
    width: 100%;
    margin-bottom:10px;
    border: none;
    border-bottom: 1px solid #aeaeae;
    animation: fadeIn 0.2s;
    resize: none;
  }
  &--Group{
    &.selected{
      margin-bottom: 5px;
      padding-top: 5px;
    }
  }
  &--GroupOption{
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    i{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin: 1px 10px 3px 3px;
      padding: 4px;
      vertical-align: middle;
      border-radius: 50%;
      border: 1px solid #aeaeae;
      color: var(--button-color);
      font-size: 22px;
      font-style: unset;
    }
    span{
      font-size: 0.9vw;
      margin-left: 3px;
    }
  }
  &--GroupValues{
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    &.opened{
      padding: 5px 5px 5px 20px;
      max-height: 2000px;
      transition: max-height 0.25s ease-in;
    }
  }
  @media only screen and ( max-width: 769px ) {
    flex-direction: column;
    &__Container{
      width: 100%;
      margin-bottom: 5px;
      order:0!important;
    }
    &__Option{
      span{
        background: white;
      }
    }
    &--GroupOption i {
      background: white;
    }
    // &__Addtxt{
    //   background:transparent;
    //   &::placeholder{
    //     color: white;
    //   }
    // }
  }
}

@keyframes fadeIn{
  from{opacity:0;}
  to{opacity:1;}
}
</style>
