<template lang="html">
  <div :class="'HeatMapQuestion HeatMapQuestion'+_uid">
    <p class="HeatMapQuestion--Info">
      <i class="fa fa-info-circle"></i>
      {{$parent.translationMethod('question_heatmap_description')}}
    </p>

    <HeatMap
      :ref="'HeatMap-'+_uid"
      :heatmapPointDefinitions="question.question.heatmapPointDefinitions"
      :images="question.question.images"
      :containerClassName="'HeatMapQuestion'+_uid"
      v-on:send-answers="sendAnswers()"
      v-on:testing-ok="testing = true"/>
      <!-- Remove HeatMapResults component for testing -->
      <!-- <HeatMapResults
        v-for="(img, index) in question.question.images"
        :answersNum="1"
        v-if="testing"
        :image="img"
        :heatMapAnswers="getAnswersByImageIndex(index)"
        :heatmapPointDefinitions="getPinsByImageIndex(index)"/> -->
  </div>
</template>

<script>
import HeatMap from '../HeatMap';
import HeatMapResults from '../HeatMap/HeatMapResults'
export default {
  name: 'HeatMapQuestion',

  props: {
    question:{
        type: Object,
        default: null
    },
  },
  components:{
    HeatMap,
    HeatMapResults
  },
  data(){
    return{
        testing:false,
    }
  },
  methods:{
    sendAnswers(){
      let valid = true
      for(let pin of this.$refs['HeatMap-'+this._uid].pins){
        if(!pin.added) valid = false
      }
      if (this.question.question.textEmpty != null && this.question.question.textEmpty) {
        valid = true
      }
      let answers = this.$refs['HeatMap-'+this._uid].pins
      let data = {
        hmvalues:answers.map(function(pin) {
          let answer = {
            identifier: pin.identifier,
            type: pin.type,
            x: pin.x,
            y: pin.y,
            comment: pin.comment,
            imageIndex: pin.imageIndex
          }
          return answer
        })
      }
      this.$emit('send-answers', {answer:data,question:this.question, answerValid: valid})
    },
    getAnswersByImageIndex(index){
      let pointsByImageIndex = _.filter(this.$refs['HeatMap-'+this._uid].pins, function (p) { return p.imageIndex == index });
      let data = {
        hmvaluesByType:{
          RED: _.filter(pointsByImageIndex, function (p) { return p.type == "RED" }),
          GREEN:_.filter(pointsByImageIndex, function (p) { return p.type == "GREEN" }),
        },
        hmvaluesByPoint:{}
      }
      let pointsByIndex = {}
      let pointIndex = 0
      for(let point of pointsByImageIndex){
        if (pointsByIndex[pointIndex] == undefined) pointsByIndex[pointIndex] = []
        pointsByIndex[pointIndex].push(point)
        pointIndex++
      }
      data.hmvaluesByPoint = pointsByIndex
      return data
    },
    getPinsByImageIndex(index){
      return _.filter(this.question.question.heatmapPointDefinitions, function (p) { return p.imageIndex == index });
    },
  },
  async mounted() {
    if (this.question.question.textEmpty != null && this.question.question.textEmpty) {
      this.sendAnswers()
    }
  }
}
</script>

<style lang="scss" scoped>
.HeatMapQuestion{
  &--Info{
    margin-bottom: 10px;
    color: #004085;
    background-color: #cce5ff;
    border:1px solid #b8daff;
    padding: 5px;
    i{
      color: #007bff;
    }
  }
}
</style>
