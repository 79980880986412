<template lang="html">
  <div class="Matrix">
    <MatrixBasic :question="question" v-if="!question.question.matrixRadioButton" v-on:send-answers="$emit('send-answers', $event)"/>
    <MatrixRadioButton :question="question" v-if="showRadioButton" v-on:send-answers="$emit('send-answers', $event)" ref="MatrixRadioButton"/>
    <MatrixRadioButtonBasic :question="question" v-if="showRadioButtonBasic" v-on:send-answers="$emit('send-answers', $event)" :invertedTable="question.question.matrixVisualization == 'RADIO_BUTTONS_BASIC_INVERTED'"/>
    <MatrixExtended :question="question" v-if="question.question.matrixExtended" v-on:send-answers="$emit('send-answers', $event)"/>
  </div>
</template>

<script>
import {MatrixRadioButtonBasic, MatrixRadioButton, MatrixBasic, MatrixExtended} from './Matrix/index'

export default {
  name: 'MatrixRefactor',
  components:{
    MatrixRadioButton,
    MatrixRadioButtonBasic,
    MatrixBasic,
    MatrixExtended
  },
  props: {
    question:{
      type: Object,
      default: null
    },
  },
  data(){
    return{
      answers:[],
      addedTexts:{},
      showRadioButton:false,
      showRadioButtonBasic: false
    }
  },
  methods:{
    addAnswer(id,val,index){
      let answerValid = true
      this.answers[index] = id+"-"+this.getOriginalIndex(val)
      for (let i in this.question.question.values) {
        if (this.answers[i] == undefined) answerValid = false
        if(this.question.question.values[i].addtxt && (this.addedTexts[this.question.question.values[i].id] == undefined || this.addedTexts[this.question.question.values[i].id].length < 2)) answerValid = false
      }
      this.$emit('send-answers', {answer:{matrixVals:this.answers},question:this.question, answerValid: answerValid})
      if((this.question.question.matrixVisualization == 'RADIO_BUTTONS' || this.question.question.matrixVisualization == 'REGULAR') && !this.question.question.matrixExtended && this.question.question.matrixRadioButton){
        this.$refs.MatrixRadioButton.next()
      }
      if(window.innerWidth <=769 && (this.question.question.matrixVisualization == 'RADIO_BUTTONS_BASIC' || this.question.question.matrixVisualization == 'RADIO_BUTTONS_BASIC_INVERTED') && !this.question.question.matrixExtended){
        this.$refs.MatrixRadioButton.next()
      }
      if(this.showRadioButton == true){
        this.$emit('scrollToTopOfQuestion')
      }
    },
    addText(event,value){
      let answerValid = true
      this.addedTexts[value.id] = event.target.value
      for (let i in this.question.question.values) {
        if (this.answers[i] == undefined) answerValid = false
        if(this.question.question.values[i].addtxt && (this.addedTexts[this.question.question.values[i].id] == undefined || this.addedTexts[this.question.question.values[i].id].length < 2)) answerValid = false
      }
      this.$emit('send-answers', {answer:{matrixVals:this.answers,addTexts:this.addedTexts},question:this.question, answerValid: answerValid})
    },
    isAnswered(i){
      return this.answers[i] != undefined
    },
    isSelected(index,value){
      if(this.answers[index] != undefined){
        return parseInt(this.answers[index].split("-")[1]) >= parseInt(value)
      }
      return false
    },
    getOriginalIndex(index){
      return this.question.question.radioOptionIndexes != undefined ? this.question.question.radioOptionIndexes[index] : index
    },
    translationMethod(translation){
      this.$parent.translationMethod(translation)
    },
    windowResize(){
      if((this.question.question.matrixVisualization == 'RADIO_BUTTONS_BASIC' || this.question.question.matrixVisualization == 'RADIO_BUTTONS_BASIC_INVERTED') && !this.question.question.matrixExtended){
        if (window.innerWidth <=769) {
          this.showRadioButtonBasic = false
          this.showRadioButton = true
        }
        else{
          this.showRadioButtonBasic = true
          this.showRadioButton = false
        }
      }
    }
  },
  async mounted(){
    this.windowResize()
    if((this.question.question.matrixVisualization == 'RADIO_BUTTONS' || this.question.question.matrixVisualization == 'REGULAR') && !this.question.question.matrixExtended && this.question.question.matrixRadioButton) this.showRadioButton = true
    window.addEventListener('resize', () => { this.windowResize() } )
    this.question.question.questionAddtxt = true
    if (this.question.answer){
       this.answers = this.question.answer.matrixVals
       this.addAnswer()
    }
  },
  created(){
    if(this.question.question.matrixRadioButton && this.question.question.matrixExtended){
      for(let i in this.question.question.values){
        this.answers[i] = []
        for(let i2 in this.question.question.radioOptionTexts){
          this.answers[i].push('99')
        }
      }
    }
  }
}
</script>
