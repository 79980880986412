<template lang="html">
  <div class="MatrixRadioButton">
    <div class="MatrixRadioButton__Values">
      <i class="fa fa-arrow-left arrow" :class="{'disabled': current == 0}" aria-hidden="true" v-on:click="previous()"></i>
      <div class="MatrixRadioButton__Values--scroll" id="matrix-scroller">
        <div :id="'matrix-scroller_'+question.question.id" class="scrollFalse">
          <p v-for="(v, index) in question.question.values" :class="{'selected':current == index}" :id="'p_'+question.question.order+'-'+v.id+'-'+index">
            <p v-html="v.value"></p>
            <i class="fa fa-check-circle-o" v-if="$parent.isAnswered(index)"></i>
            <span>{{index + 1}} / {{question.question.values.length}}</span>
          </p>
        </div>
      </div>
      <i class="fa fa-arrow-right arrow" :class="{'disabled': current == question.question.values.length - 1}" aria-hidden="true" v-on:click="next()"></i>
    </div>
    <div class="MatrixRadioButton__Options" v-for="(v,index) in question.question.values" v-show="current == index">
      <div v-for="(txt,i) in radioOptionTexts">
        <input type="radio" :id="'answer_'+question.question.order+'_'+v.id+'_'+txt.index" :name="'answer_'+question.question.order+'_'+v.id +'_'+txt.index" :value="v.id +'-'+txt.index" v-model="$parent.answers[index]" v-on:click="$parent.addAnswer(v.id,txt.index,index)">
        <label :for="'answer_'+question.question.order + '_' + v.id+'_'+txt.index">
          <span v-html="txt.option">
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatrixRadioButton',

  props: {
    question:Object,
  },

  data() {
    return {
      answers:[],
      current:0,
      percents:[]
    }
  },
  computed:{
    radioOptionTexts(){
      const options = this.question.question.radioOptionTexts
      let newOptions = options.map(function(o,i) {
        return { option:o, index: i }
      })
      if(this.question.question.rdmOptionTexts){
        newOptions = this.shuffleArray(newOptions)
      }
      return newOptions
    }
  },
  methods: {
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    },
    setLeftPosition(){
      const leftStyle = (-100 * this.current) + '%'
      document.getElementById('matrix-scroller_'+this.question.question.id).style.left = leftStyle
    },
    next(){
      if( this.current == this.question.question.values.length - 1 ) return
      this.current++
      this.setLeftPosition()
    },
    previous(){
      if ( this.current == 0 ) return
      this.current--
      this.setLeftPosition()
    },
  }
}
</script>

<style lang="scss" scoped>
.arrow{
  background: lightgray;
  border-radius: 50%;
  padding: 5px;
  cursor:pointer;
  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.MatrixRadioButton{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__Values{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--scroll{
      overflow: hidden;
      border-radius: 3px;
      border: 1px solid lightgray;
      padding: 5px;
      margin:5px;
      width: 100%;
      &::-webkit-scrollbar-track{
        background-color: #e6e6e6;
      }
      &::-webkit-scrollbar {
        background:linear-gradient(to right,var(--main-color) , var(--secondary-color)); ;
        height: 15px;
        width: 10px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-image: -webkit-gradient(linear,40% 0%,75% 84%,from(var(--main-color)),to(var(--secondary-color)),color-stop(.6,var(--secondary-color)));
      }
      p{
        margin: auto;
        padding: 10px;
        width: fit-content;
        max-width: 350px;
        min-height: 72%;
        background-color: #fff;
        border-radius: 8px;
        text-align: center;
        position: relative;
        opacity: 0.4;
        cursor: pointer;
        transition: left 1s, opacity 1s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &.selected{
          color: var(--main-color);
          opacity:1;
          z-index: 1;
          p{
            color: var(--main-color);
            opacity:1;
            z-index: 1;
          }
        }
        i{
          color: var(--main-color);
        }
        span{
          border-radius: 6px;
          background-color: #dcdee1;
          width: fit-content;
          padding: 0 7px;
          height: 25px;
          font-size: 11px;
          position: absolute;
          top: -10px;
          color: black;
          display: flex;
          align-items: center;
        }
      }
      .scrollFalse{
        width:100%;
        min-height: 200px;
        // overflow : hidden;
        position:relative;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(100, minmax(100%, 1fr) );
        transition: left 1s, opacity 1s;
      }
    }
  }
  &__Options{
    div{
      font-family: 'Oswald', sans-serif;
      margin: 15px 5px 5px 0;
      input {
        display: none;
        &:checked + label, &:hover + label{
          color: var(--text-color);
          span{
            background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
            color: white;
            animation: fadeIn 0.2s;
            position: relative;
          }
        }
      }
      label{
        display: flex;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        span{
          border: 1px solid #aeaeae;
          width: 100%;
          line-height: 1;
          padding: 6px 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  @media only screen and ( max-width: 769px ) {
    &__Values{
      &--scroll{
        p{
          opacity:0;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
