<template lang="html">
  <div class="Maxdiff">
    <div class="Maxdiff__Progress">
      <div class="Maxdiff__Progress--current" :style="{width: progress + '%' }">
        {{Math.floor(progress)}}%
      </div>
    </div>
    <div class="Maxdiff__Container">
      <div class="Maxdiff__Container__Values">
        <div class="Maxdiff__Container--lblLeft">
          {{question.question.rankingLeftLabel}}
        </div>
        <div class="Maxdiff__Container--text">

        </div>
        <div class="Maxdiff__Container--lblRight">
          {{question.question.rankingRightLabel}}
        </div>
      </div>
      <div class="Maxdiff__Container__Values" v-for="(maxdiffValId,i) in question.question.maxdiffQuestions[currentMaxdiffValues]">
        <div class="Maxdiff__Container--lblLeft">
          <div class="Maxdiff__Container--input">
            <input type="radio" :name="'Maxdiff_rll_'+question.question.id" :id="'Maxdiff_rll_'+question.question.id+maxdiffValId+i" :value="maxdiffValId" v-model="answers[currentMaxdiffValues].left" :disabled="answers[currentMaxdiffValues].right==maxdiffValId">
            <label :for="'Maxdiff_rll_'+question.question.id+maxdiffValId+i">
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
        <div class="Maxdiff__Container--text">
          {{getValueName(maxdiffValId)}}
        </div>
        <div class="Maxdiff__Container--lblRight">
          <div class="Maxdiff__Container--input">
            <input  type="radio" :name="'Maxdiff_rrl_'+question.question.id" :id="'Maxdiff_rrl_'+question.question.id+maxdiffValId+i" :value="maxdiffValId" v-model="answers[currentMaxdiffValues].right" :disabled="answers[currentMaxdiffValues].left==maxdiffValId">
            <label :for="'Maxdiff_rrl_'+question.question.id+maxdiffValId+i">
              <span>
                <i></i>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="Maxdiff__Actions">
      <button class="next" type="button" name="button" v-on:click="nextStep()" :disabled="currentMaxdiffValues == question.question.maxdiffQuestions.length || answers[currentMaxdiffValues].left==null || answers[currentMaxdiffValues].right==null">{{$parent.translationMethod('Button_next')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maxdiff',
  props: {
    question:{
        type: Object,
        default: null
    },
  },

  data() {
    return {
      answers:[{left:null,right:null}],
      currentMaxdiffValues:0,
      progress:0,
    }
  },

  methods: {
    nextStep(){
      this.answers[this.currentMaxdiffValues].questionValues = this.question.question.maxdiffQuestions[this.currentMaxdiffValues]
      if(this.currentMaxdiffValues+1 == this.question.question.maxdiffQuestions.length){
        this.progress = 100
        this.$emit('send-answers', {answer:{maxdiffVals:this.answers},question:this.question, answerValid: true})
      }
      else if(this.answers[this.currentMaxdiffValues+1] == undefined){
        this.answers.push({left:null,right:null})
        this.currentMaxdiffValues++
        this.progress = (100 * this.currentMaxdiffValues) / this.question.question.maxdiffQuestions.length
      }
    },
    getValueName(maxdiffValId){
      for(let v of this.question.question.values){
        if (v.id == maxdiffValId) return v.value
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.Maxdiff{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__Progress{
    width:60%;
    height: 15px;
    border-radius:12px;
    background: white;
    margin:10px 0;
    &--current{
      min-width:7%;
      transition: 0.2s;
      font-size:12px;
      height: 15px;
      border-radius:12px;
      text-align: center;
      color: var(--text-color);
      background: linear-gradient(to right, var(--secondary-color), var(--main-color));
    }
  }
  &__Container{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    background: white;
    &__Values{
      width: 100%;
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid lightgray;
      text-align: center;
      padding: 10px 0;
      &:nth-child(even) {
        background: #f2f2f2
      }
    }
    &--lblLeft{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--lblRight{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--text{
      width: 100%;
    }
    &--input{
      display: flex;
      input {
      	display: none;
        &:checked + label{
          color: var(--main-color);
          i{
            transition: 0.1s;
            background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
            transform: scale(1);
          }
        }
      }
      label{
        display: flex;
        font-weight: 500;
        cursor: pointer;
        margin:0!important;
        span{
          display: flex;
          width: 20px;
          min-width: 20px;
          height: 20px;
          padding:4px;
          vertical-align: middle;
          border-radius: 50%;
          border: 1px solid #aeaeae;
          i{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            transform: scale(0);
          }
        }
      }
    }
  }
  &__Actions{
    margin-top:10px;
    button{
      font-family: 'Bariol';
      font-weight: 600;
      font-size: 1.1vw;
      border-radius: 3px;
      border: none;
      padding: 4px 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color:white;
      margin: 20px 0;
      &.next{
        background: var(--button-color);
      }
      &.previous{
        background: transparent;
        color: #5b5b5b;
      }
      &:disabled{
        cursor:not-allowed;
        opacity: 0.6;
      }
    }
  }
  @media only screen and ( max-width: 769px ) {

  }
}
</style>
