<template lang="html">
  <div class="QuestionsBlock">
      <Question
        ref="question"
        class="QuestionsBlock__Question"
        :id="'question_'+index"
        v-for="(q,index) in questions"
        v-bind:item="q.question"
        v-bind:index="index"
        v-bind:key="q.question.id"
        v-show="!(isMobileSize() && q != undefined && questions[currentQuestionIndex].question.id != q.question.id)"
        :class="{'not-selected':questions[currentQuestionIndex].question.id != q.question.id,'hidden':q.question.type == 'INTERNAL_VARIABLE' && q.question.secondaryType == null}"
        :question="q"
        :hidePreviousButton="!enablePreviousButton(q)"
        :isSavingAnswer="isSavingAnswer"
        :enviroment="enviroment"
        :groupedQuestion="groupedQuestion"
        :currentQuestionOrder="''+currentQuestionOrder+''"
        :currentQuestionTimer="''+questions[currentQuestionIndex].question.id+''"
        :currentQuestionIndex="'' + currentQuestionIndex+''"
        :testId="testId"
        v-on:previous-question="previousQuestion"
        v-on:save-answer="saveAnswer($event)"
        v-on:set-timer-killer="setTimerKiller($event)"
        @scrollToTopOfQuestion = "scrollToQuestion(false, currentQuestionIndex)"/>
  </div>
</template>

<script>
import Question from '../Question'
export default {
  name: 'QuestionsBlock',

  props: {
    questions: {
      type: Array,
      default: null
    },
    currentQuestionIndex:{
      type: Number,
      default:null,
    },
    currentGroupedQuestionIndex: {
      type: Number,
      default: null
    },
    currentQuestionOrder:{
      type:Number,
      default:null
    },
    isSavingAnswer:{
      type: Boolean,
      default: false,
    },
    enviroment:{
      type: String,
      default: "WAT",
    },
    isPreview:{
      type: Boolean,
      default: false,
    },
    firstTestQuestionId:{
      type: [String, Number],
      default:null,
    },
    groupedQuestion:{
      type:Boolean,
      default:false
    },
    testId:{
      type: [Number, String],
      default:0
    }
  },
  watch: {
    currentQuestionIndex: function(newVal, oldVal) { // watch current question index
      setTimeout(() => {
        if (newVal > oldVal || newVal == 0) this.scrollToQuestion(this.isEndBlock, newVal)
        else if(newVal == 1 && this.questions[0].question.type == "INTERNAL_VARIABLE" && this.questions[0].question.secondaryType == null) this.scrollToQuestion(true, newVal) 
      }, 100);
    },
    currentGroupedQuestionIndex: function(newVal, oldVal){
      $('.QuestionsBlock').animate({scrollTop: 0},600);
    }
  },
  components:{
    Question,
  },
  data(){
    return{
        isEndBlock:false,
        isViewportChange:false,
    }
  },
  methods:{
    setErrorText(txt){
      this.$refs.question[this.currentQuestionIndex].setErrorText(txt)
    },
    saveAnswer: async function(answer){
      this.isEndBlock = answer.answer.question.question.endBlock
      await this.$emit('save-answer',answer.answer)
    },
    previousQuestion(){
      this.$emit('previous-question')
      this.scrollToQuestion(false, this.currentQuestionIndex - 1)
    },
    scrollToQuestion(isEndBlock = false, qIndex = this.currentQuestionIndex){
      if (!isEndBlock && !this.isMobileSize()) {
        let height = (window.innerHeight - $('#question_'+qIndex).innerHeight()) / 2
        if( height < 20 ) height = 100
        if ($('#question_'+qIndex)[0] != undefined) {
          $('.QuestionsBlock').animate({
            scrollTop: ($('#question_'+qIndex)[0].offsetTop - height)
          },600);
        }
      } else {
        if (this.questions != null && this.questions.length > 0 && 
            (this.questions[0].question.type == 'INTERNAL_VARIABLE' || 
            (this.questions[0].question.type == 'SELECTION' && this.questions[0].question.values.length == 1))) {
          let height = (window.innerHeight - $('#question_'+qIndex).innerHeight()) / 2
          if( height < 20 ) height = 100
          $('.QuestionsBlock').animate({
            scrollTop: ($('#question_'+qIndex)[0].offsetTop - height)
          },600);
        } else {
          $('.QuestionsBlock').animate({
            scrollTop: 0
          },600);
        }
      }
    },
    scrollToQuestionMount(){
      if (this.currentQuestionIndex > 0) this.scrollToQuestion(false, this.currentQuestionIndex)
      else $('.QuestionsBlock').animate({scrollTop: 0},600);
    },
    windowResize(){
      if (window.innerWidth <=769) {
        this.isViewportChange = true
      }
      if(window.innerWidth > 769 && this.isViewportChange){
        this.isViewportChange = false
        this.scrollToQuestion(false,this.currentQuestionIndex)
      }
    },
    enablePreviousButton(q){
      if(this.isPreview){
        if(this.firstTestQuestionId == q.question.id && parseInt(q.question.order) < 2 ) return false
        else return true
      }
      if(this.currentQuestionIndex == 0 || q.question.hidePreviousButton) return false
    },
    isMobileSize(){
      return window.innerWidth <=769
    },
    setTimerKiller(){
      this.$emit('set-timer-killer');
    }
  },
  async mounted(){
    this.scrollToQuestionMount()
    window.addEventListener('resize', () => { this.windowResize() } )
    if (this.isMobileSize()) {
      this.isViewportChange = true
    }
  }
}
</script>

<style lang="scss" scoped>
.QuestionsBlock{
  width: 100%;
  padding-bottom:300px;
  &__Question{
    margin-bottom: 10%;
  }
  @media only screen and ( max-width: 769px ) {
    padding: 10% 5%;
    &__Question{
      margin: 0;
      margin-bottom: 60px;
    }
  }
}

</style>
