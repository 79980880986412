<template lang="html">
  <div class="CarrouselQuestions">
    <MultipleAnswers
      :questions="question.groupedQuestions"
      :radioOptionTexts="this.question.groupedQuestions.map(q => q.question.text)"
      v-on:send-answers="$emit('send-answers', $event)"/>
  </div>
</template>

<script>
import MultipleAnswers from './MultipleAnswers'
export default {
  name: 'GroupedQuestions',

  props: {
    question:{
        type: Object,
        default: null
    },
  },
  components:{
    MultipleAnswers,
  },
  methods:{
    translationMethod(translation){
      this.$parent.translationMethod(translation)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
