<template lang="html">
  <div class="MultiNumber">
    <div class="Number" v-for="i in question.question.textNum">
      <p v-if="question.question.textDescriptions">{{question.question.textDescriptions[i-1]}}</p>
      <div class="Number__Container">
        <input
          :id="'inputEl'+question.question.order+'-'+i"
          :name="'answer_'+question.question.order"
          type="text"
          v-model="answers[i-1]"
          placeholder="0">
        <div class="Number__Container__Units" v-if="question.question.units != undefined && question.question.units != null && question.question.units != ''">
          {{question.question.units}}
        </div>
      </div>
    </div>
    <div class="MultiNumber--error" v-show="wrongAnswer">
      {{$parent.translationMethod('question_bad_answer')}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiNumber',

  props: {
    question:{
      type: Object,
      default: null
    },
  },
  updated: function(){
    this.$nextTick(function () {
      this.addAnswer()
    })
  },
  data() {
    return {
      answers:[],
      fakeAnswers:{multipleNumbers:[]},
      isDecimal:false,
      wrongAnswer:false,
      defaultValue:null,
    }
  },
  methods:{
    addAnswer(){
      let answerValid = false
      for(var i = 0; i < this.question.question.textNum; i++){
        this.fakeAnswers.multipleNumbers[i] = this.answers[i]
      }
      for(var i = 0; i < this.question.question.textNum; i++){
        if(this.isNumber(this.fakeAnswers.multipleNumbers[i])) answerValid = true
        else if(this.defaultValue!=null && (this.fakeAnswers.multipleNumbers[i] == null || this.fakeAnswers.multipleNumbers[i] == undefined || this.fakeAnswers.multipleNumbers[i] == "")){
          this.fakeAnswers.multipleNumbers[i] = this.defaultValue
          answerValid = true
        }
        else{
          answerValid = false
          this.$emit('send-answers', {answer:this.fakeAnswers,question:this.question, answerValid:answerValid})
          return
        }
        this.fakeAnswers.multipleNumbers[i] = ""+this.fakeAnswers.multipleNumbers[i]+""
        if(this.isDecimal) this.fakeAnswers.multipleNumbers[i] = parseFloat(this.fakeAnswers.multipleNumbers[i].replace(',','.'))
        else this.fakeAnswers.multipleNumbers[i] = parseInt(this.fakeAnswers.multipleNumbers[i])
      }
      if(this.question.question.multiNumberCondition != null && this.question.question.multiNumberCondition != undefined && this.question.question.multiNumberCondition != ""){
        if(!this.validateCondition()) answerValid = false
      }
      this.$emit('send-answers', {answer:this.fakeAnswers,question:this.question, answerValid:answerValid})
    },
    isNumber(number){
      let num = ""+number+""
      if (num == undefined || num == null || num == "") return false
      if(this.isDecimal){
        let floatAnswer = num.replace(',','.')
        if(num.includes(".") ||
          num.includes("+") ||
          num.includes("e") ||
          num.includes("-") ||
            isNaN(floatAnswer)){
            return false
        }
        let comas = 0
        for(let x of num){
          if(x == ",") comas++
        }
        if (comas > 1) return false
        if(floatAnswer.includes(".")){
          let answerSplit = ""+floatAnswer+""
          answerSplit = answerSplit.split(".")
          if(answerSplit[0].length > this.question.question.digitsNum || answerSplit[1].length > this.question.question.decimalNum) return false
        }
        else if(floatAnswer.length > this.question.question.digitsNum) return false
      }
      else{
        if(num.includes(",") ||
        num.includes(".") ||
        num.includes("+") ||
        num.includes("e") ||
        num.includes("-") ||
        isNaN(num)){
          return false
        }
      }
      return true
    },
    validateCondition(){
      let valid = true
      let containsDefault = this.question.question.multiNumberCondition.includes('default')
      let c = this.question.question.multiNumberCondition.split("&")
      if(containsDefault) c.splice(-1,1)
      let operator = ""
      let first = 0
      let second = 0
      for(let el of c){
        if(el.includes("<=")) operator = "<="
        else if(el.includes(">=")) operator = ">="
        else if(el.includes("<")) operator = "<"
        else if(el.includes(">")) operator = ">"
        else if(el.includes("==")) operator = "=="
        else if(el.includes("!=")) operator = "!="
        first = el.substr(0,el.lastIndexOf(operator))
        second = el.substr(el.lastIndexOf(operator)+operator.length)
        if(operator == "<="){
          if(this.fakeAnswers.multipleNumbers[first-1] > this.fakeAnswers.multipleNumbers[second-1]) valid = false
        }
        else if(operator == ">="){
          if(this.fakeAnswers.multipleNumbers[first-1] < this.fakeAnswers.multipleNumbers[second-1]) valid = false
        }
        else if(operator == "=="){
          if(this.fakeAnswers.multipleNumbers[first-1] != this.fakeAnswers.multipleNumbers[second-1]) valid = false
        }
        else if(operator == "!="){
          if(this.fakeAnswers.multipleNumbers[first-1] == this.fakeAnswers.multipleNumbers[second-1]) valid = false
        }
        else if(operator == "<"){
          if(this.fakeAnswers.multipleNumbers[first-1] >= this.fakeAnswers.multipleNumbers[second-1]) valid = false
        }
        else if(operator == ">"){
          if(this.fakeAnswers.multipleNumbers[first-1] <= this.fakeAnswers.multipleNumbers[second-1]) valid = false
        }
      }
      if(!valid) this.wrongAnswer = true
      else this.wrongAnswer = false
      return valid
    },
  },
  async mounted(){
    if(this.question.question.digitsNum != null &&
      this.question.question.digitsNum != undefined &&
      this.question.question.digitsNum != "" &&
      this.question.question.decimalNum != null &&
      this.question.question.decimalNum != undefined &&
      this.question.question.decimalNum != "") this.isDecimal = true
    else this.isDecimal = false
    if(this.question.question.multiNumberCondition != null && this.question.question.multiNumberCondition != undefined && this.question.question.multiNumberCondition != "" && this.question.question.multiNumberCondition.includes('default')){
      let condition = this.question.question.multiNumberCondition
      this.defaultValue = condition.substring(condition.indexOf('default')).replace('default=','')
      this.addAnswer()
    }
  }
}
</script>

<style lang="scss" scoped>
.MultiNumber{
  .Number{
    margin-bottom: 10px;
  }
  .Number__Container{
    display: flex;
    align-items: center;
    input{
      outline: none;
      border: none;
      border-bottom: 1px solid #949494;
      color: #aeaeae;
      font-size: 30px;
      font-weight: bold;
      width: 100px;
      text-align: center;
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &::placeholder{
        color: #e4e4e4;
        font-size: 30px;
        font-weight: bold;
      }
    }
    &__Units{
      display: flex;
      color: #aeaeae;
      font-size: 19px;
      height: 45px;
      align-items: center;
      padding: 0 5px;
      background: white;
      border-bottom: 1px solid #949494;
    }
  }

  &--error{
    color:crimson;
  }
}
</style>
