import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Timer" }
const _hoisted_2 = { class: "Timer-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "Timer-icon" }, [
      _createElementVNode("i", { class: "fas fa-stopwatch" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.minutes) + ":" + _toDisplayString(_ctx.seconds), 1)
  ]))
}