<template lang="html">
  <div class="divide-points">
    <p class="addedPoints">{{$parent.translationMethod('missing')}} <b class="main-color">{{restantPoints}}</b> {{$parent.translationMethod('missing_of')}} <b class="secondary-color">{{question.question.pointsNum}}</b></p>
    <button v-on:click="restartPoints()">{{$parent.translationMethod('restart_points')}}</button>
    <div class="divide-points__Wrapper">
      <div class="divide-points__input" v-for="(v,vi) in question.question.values">
        <p class="valuename" v-html="v.value"></p>
        <div class="divide-points__wrapper" v-if="answers[vi]">
          <input type="range" :id="'range_'+question.question.id+'_'+vi" data-thumbwidth="20" v-model="answers[vi].value" :max="question.question.pointsNum" min="0" v-on:input="drag(vi,false)">
          <output name="rangeVal" :id="'rangeValue_'+question.question.id+'_'+vi">{{answers[vi].value}}</output>
          <div class="progressDividepoints">
            <div class="progressDividepoints--Color" :id="'progress_'+vi">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DividePoints',

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      addedPoints:0,
      restantPoints:0,
      answers:[{value:0,option:1}],
      isValueAdded:false,
    }
  },
  methods: {
    restartPoints: async function(){
      this.answers = []
      this.answers = _.map(this.question.question.values, function (o, i) {
        let data = {value : 0, option:parseInt(i)}
        return data
      })
      for (let i in this.question.question.values) {
        await this.drag(parseInt(i),true)
      }
    },
    drag(ansId,restart){
      this.addedPoints = 0
      this.restantPoints = this.question.question.pointsNum
      for (let ans of this.answers) {
         this.addedPoints = this.addedPoints + parseInt(ans.value)
      }
      this.restantPoints = this.restantPoints - this.addedPoints
      if (this.addedPoints >=this.question.question.pointsNum) {
        this.restantPoints = 0
        let newValue = this.addedPoints - this.question.question.pointsNum
        this.answers[ansId].value = parseInt(this.answers[ansId].value - newValue)
        this.addedPoints = this.question.question.pointsNum

        let control =  $('#range_'+this.question.question.id+'_'+ansId)
        let  controlMin = control.attr('min')
        let  controlMax = control.attr('max')
        let  controlVal = this.answers[ansId].value
        let  controlThumbWidth = control.data('thumbwidth')

        let range = controlMax - controlMin;

        let position = ((controlVal - controlMin) / range) * 100;
        let positionOffset = Math.round(controlThumbWidth * position / 100) - (controlThumbWidth / 2);
        let output = $('#rangeValue_'+this.question.question.id+'_'+ansId)

        output.css('left', 'calc(' + position + '% - ' + positionOffset + 'px)')
        output.text(controlVal);
        positionOffset += 8
        $('#progress_'+ansId).css({"width": 'calc(' + position + '% - ' + positionOffset + 'px)'})
      }
      else{
        let control =  $('#range_'+this.question.question.id+'_'+ansId)
        let  controlMin = control.attr('min')
        let  controlMax = control.attr('max')
        let  controlVal = control.val()
        let  controlThumbWidth = control.data('thumbwidth')

        let range = controlMax - controlMin;

        let position = ((controlVal - controlMin) / range) * 100;
        let positionOffset = Math.round(controlThumbWidth * position / 100) - (controlThumbWidth / 2);
        let output = $('#rangeValue_'+this.question.question.id+'_'+ansId)
        if (restart) {
          position = 0;
          positionOffset = -10;
          controlVal = 0
        }
        output.css('left', 'calc(' + position + '% - ' + positionOffset + 'px)')
        output.text(controlVal);
        positionOffset += 8
        $('#progress_'+ansId).css({"width": 'calc(' + position + '% - ' + positionOffset + 'px)'})
      }
      let answerValid = true
      if (this.addedPoints != this.question.question.pointsNum) answerValid = false
      this.$emit('send-answers', {answer:{divideVals:this.answers},question:this.question, answerValid:answerValid})


    },
  },
  updated: function(){
    this.$nextTick(function () {
      if (!this.isValueAdded) {
        if(this.question.answer){
          this.answers = this.question.answer.divideVals
          for (let i in this.answers) {
            let control =  $('#range_'+this.question.question.id+'_'+i)
            let  controlMin = control.attr('min')
            let  controlMax = control.attr('max')
            let  controlVal = this.answers[i].value
            let  controlThumbWidth = control.data('thumbwidth')
            let range = controlMax - controlMin;
            let position = ((controlVal - controlMin) / range) * 100;
            let positionOffset = Math.round(controlThumbWidth * position / 100) - (controlThumbWidth / 2);
            let output = $('#rangeValue_'+this.question.question.id+'_'+i)

            output.css('left', 'calc(' + position + '% - ' + positionOffset + 'px)')
            output.text(controlVal);
            positionOffset += 8
            $('#progress_'+i).css({"width": 'calc(' + position + '% - ' + positionOffset + 'px)'})
          }
          this.$emit('send-answers', {answer:{divideVals:this.answers},question:this.question, answerValid:true})
        }
      }
      this.isValueAdded = true
    })
  },
  async mounted(){
    this.answers = _.map(this.question.question.values, function (o, i) {
      let data = {value : 0, option:o.id}
      return data
    })
    this.restantPoints = this.question.question.pointsNum
  },
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');
.range-control {
  position: relative;
}

input[type=range] {
  outline: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  margin: 18px 0;
  width: 100%;
  position: relative;
  z-index: 3;
  display: block;
}

input[type=range]::-ms-tooltip{
    display:none;
}
input::-ms-track {
  width: 100%;
  cursor: pointer;
  animate: 0.2s;
  height: 0px;
}

input::-ms-thumb {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    margin-top:-8px;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
}
input[type=range]::-webkit-slider-runnable-track{
  width: 100%;
  cursor: pointer;
  animate: 0.2s;
  height: 0px;
}

input[type=range]::-webkit-slider-thumb {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  margin-top:-8px;
  border:none;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance : none;
}

output {
  position: absolute;
  top: 0px;
  left: calc(0% - -10px);
  color: white;
  transform: translateX(-50%);
  text-align: center;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  z-index: 2;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  margin-top:-8px;
  background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
  display: block;
  padding-top: 7px;
  cursor: pointer;
}

input[type=range]:active + output {
  display: block;
  transform: translateX(-50%);
}
.divide-points{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  button{
    border: none;
    background: none;
    padding: 10px 0;
    color: gray;
    cursor: pointer;
    outline: none;
    color: var(--main-color);
  }
  .addedPoints{
    background: #f6f6f6;
    color: black;
    text-transform: uppercase;
    padding: 2px 5px;
  }
  .main-color{
    color: var(--main-color);
  }
  .secondary-color{
    color: var(--secondary-color);
  }
  .valuename{
    font-family: 'Nunito', sans-serif;
    margin: 0 0 10px;
  }
  &__Wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width:100%;
  }
  &__wrapper{
    position:relative;
    .progressDividepoints{
      height: 6px;
      width: 100%;
      background: #f6f6f6;
      z-index:1;
      position: absolute;
      top: 0px;
      border-radius: 6px;
      &--Color{
        background: linear-gradient(to right,var(--secondary-color) , var(--main-color));
        height: 6px;
        width: 0;
        border-radius: 6px;
      }
    }
  }
  &__input{
    width: calc(100% / 3);
    margin-right: 15%;
  }
  @media only screen and ( max-width: 769px ) {
    .addedPoints{
      font-size: 15px;
    }
    .divide-points{
      &__input{
        width: 100%;
      }
    }
  }
}
</style>
