<template lang="html">
  <div class="MatrixRadioButtonBasic">
    <div class="MatrixRadioButtonBasic--overflow">
      <table v-if="!invertedTable">
        <thead>
          <tr>
            <th></th>
            <th v-for="(txt,i) in question.question.radioOptionTexts" v-html="txt"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v,index) in question.question.values">
            <th v-html="v.value"></th>
            <td v-for="(txt,i) in question.question.radioOptionTexts">
              <div class="customInputCheckBox">
                <input :id="'answer_'+question.question.order + '_' + v.id+i" type="radio" :name="'answer_'+question.question.order+'_'+'index'+index" :value="v.id +'-'+$parent.getOriginalIndex(i)" v-model="$parent.answers[index]" v-on:click="$parent.addAnswer(v.id,i,index)">
                <label :for="'answer_'+question.question.order + '_' + v.id+i">
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="invertedTable">
        <thead>
          <tr>
            <th></th>
            <th v-for="(v,i) in question.question.values" v-html="v.value"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(txt,i) in question.question.radioOptionTexts">
            <th v-html="txt"></th>
            <td v-for="(v,index) in question.question.values">
              <div class="customInputCheckBox">
                <input :id="'answer_'+question.question.order + '_' + v.id+i" type="radio" :name="'answer_'+question.question.order+'_'+'index'+index" :value="v.id +'-'+i" v-model="$parent.answers[index]" v-on:click="$parent.addAnswer(v.id,i,index)">
                <label :for="'answer_'+question.question.order + '_' + v.id+i">
                  <span>
                    <i></i>
                  </span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatrixRadioButtonBasic',

  props: {
    question:Object,
    invertedTable:{
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.customInputCheckBox{
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    display: none;
    &:checked + label{
      color: var(--main-color);
      i{
        transition: 0.1s;
        background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
        transform: scale(1);
      }
    }
  }
  label{
    span{
      display: flex;
      width: 20px;
      min-width: 20px;
      height: 20px;
      padding:4px;
      vertical-align: middle;
      border-radius: 50%;
      border: 1px solid #aeaeae;
      i{
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transform: scale(0);
      }
    }
  }
}
table{
  text-align: center;
  border-collapse: collapse;
  th{
    padding: 0px 5px 10px 5px;
  }
  td{
    cursor: pointer;
  }
  thead th{
    text-align: center;
  }
  tbody th{
    text-align: left;
  }
}

.MatrixRadioButtonBasic{
    &--overflow{
      overflow-x: auto;
      &::-webkit-scrollbar-track{
        background-color: #e6e6e6;
      }
      &::-webkit-scrollbar {
        background-color: #8134ff;
        width: 3px;
        height: 3px;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background: var(--main-color);
      }
    }
}
</style>
