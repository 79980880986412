<template>
  <div class="DecimalNumber">
    <div class="DecimalNumber--Input">
      <input type="text" v-model="answer" placeholder="0" :disabled="notResponse">
      <div class="DecimalNumber__Units" v-if="question.question.units != undefined && question.question.units != null && question.question.units != ''">
        {{question.question.units}}
      </div>
    </div>
    <div class="DecimalNumber__NotResponse" v-if="question.question.notResponse">
      <div>
        <input type="checkbox" :id="'answer_'+question.question.order" :name="'answer_'+question.question.order" v-on:change="notResponseAnswer()" v-model="notResponse">
        <label :for="'answer_'+question.question.order">
          <span>
          </span>
          <i class="fa fa-check"></i>
          {{question.question.notResponseText}}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DecimalNumber',

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      answer:null,
      notResponse:false
    }
  },
  updated: function(){
    this.$nextTick(function () {
      this.addAnswer()
    })
  },
  methods: {
    notResponseAnswer(){
      let answerValid = true
      let answer = {
        decVal:999999
      }
      if(!this.notResponse){
        this.addAnswer()
        return
      }
      this.$emit('send-answers', {answer:answer,question:this.question, answerValid: answerValid})
    },
    addAnswer(){
      let answerValid = false
      if(this.answer == undefined) return this.$emit('send-answers', {answer:null,question:this.question, answerValid: false})
      let floatAnswer = this.answer.replace(',','.')
      if (this.answer != null && this.answer.length > 0 && parseFloat(floatAnswer) >= 0) answerValid = true
      if (this.answer != null){
        if(this.answer.includes(".") ||
        this.answer.includes("+") ||
        this.answer.includes("e") ||
        this.answer.includes("-") ||
        isNaN(floatAnswer)){
          answerValid = false
        }
        let comas = 0
        for(let x of this.answer){
          if(x == ",") comas++
        }
        if (comas > 1) answerValid = false
        if(floatAnswer.includes(".")){
          let answerSplit = ""+floatAnswer+""
          answerSplit = answerSplit.split(".")
          if(answerSplit[0].length > this.question.question.digitsNum || answerSplit[1].length > this.question.question.decimalNum) answerValid = false
        }
        else if(floatAnswer.length > this.question.question.digitsNum) answerValid = false
      }
      this.$emit('send-answers', {answer:{decVal:floatAnswer},question:this.question, answerValid: answerValid})
    },
  },
  async mounted(){
    if (this.question.answer) {
      this.answer = this.question.answer.decVal
      this.$emit('send-answers', {answer:{decVal:this.answer},question:this.question, answerValid: true})
    }
  }
}
</script>

<style lang="scss" scoped>
.DecimalNumber{
  display: flex;
  gap: 10px;  
  flex-direction: column;
  &--Input{
    display: flex;
    align-items: center;
  }
  input{
    outline: none;
    border: none;
    border-bottom: 1px solid #949494;
    color: #aeaeae;
    font-size: 30px;
    font-weight: bold;
    width: 100px;
    text-align: center;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder{
      color: #e4e4e4;
      font-size: 30px;
      font-weight: bold;
    }
  }
  &__Units{
    display: flex;
    color: #aeaeae;
    font-size: 19px;
    height: 45px;
    align-items: center;
    padding: 0 5px;
    background: white;
    border-bottom: 1px solid #949494;
  }
  &__NotResponse{
    div{
      width: 100%;
      display: flex;
      input {
        display: none;
        &:checked + label{
          color: var(--main-color);
          font-weight: 700;
          span{
            display:none;
          }
          i{
            display:block;
          }
        }
      }
      label{
        display: flex;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        i{
          display: none;
          font-size:25px;
          height: 26px;
          margin-right: 8px;
          background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        span{
          display: flex;
          width: 20px;
          min-width: 20px;
          height: 20px;
          margin: 3px 10px 3px 3px;
          padding:4px;
          vertical-align: middle;
          border: 1px solid #aeaeae;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
