<template lang="html">
  <div class="imageChoice" :class="{'IE11':isIE11()}">
    <div class="imageChoice--Wrapper" v-for="v in question.question.values">
      <div class="imageChoice__Img" v-on:click="addAnswer(v)" :style="{ backgroundImage: 'url(\'' + v.image + '\')', backgroundSize: v.imageVisualization ? v.imageVisualization : 'cover' }">
        <div class="selectionInput">
          <input type="radio" :id="'answer_'+question.question.order + '_' + v.id" :name="'answer_'+question.question.order" :value="v.id" v-model="answer">
          <label :for="'answer_'+question.question.order + '_' + v.id">
            <span class="false-input">
            </span>
            <i class="fa fa-check check"></i>
            <div class="expand">
              <SilentboxSingle :src="v.image" :autoplay="true" description="">
                <i class="fa fa-expand fa-sm"></i>
              </SilentboxSingle>
            </div>
          </label>
        </div>
      </div>
      <p class="imageChoiceTitle">{{v.value}}</p>
    </div>
  </div>
</template>

<script>
import SilentboxSingle from '../vue-silentbox/components/single';

export default {
  name: 'ImageChoice',

  components:{
    SilentboxSingle,
  },

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      answer:undefined,
    }
  },

  methods: {
    addAnswer(value){
      this.answer = value.id
      this.$emit('send-answers', {answer:{val:this.answer},question:this.question, answerValid: true})
    },
    isIE11(){
      return !!window.MSInputMethodContext && !!document.documentMode;
    },
  },
  async mounted(){
    if (this.question.answer) {
      this.answer = this.question.answer.val
      this.$emit('send-answers', {answer:{val:this.answer},question:this.question, answerValid: true})
    }
  }
}
</script>

<style lang="scss" scoped>
.IE11{
  i.check{
    background:none!important;
    color: var(--main-color);
  }
}
.imageChoice{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  &--Wrapper{
    margin: 0 15px 15px 0;
    width: 180px;
  }
  &__Img{
    width: 180px;
    height: 105px;
    border:1px solid #aeaeae;
    position:relative;
    background-position: center;
    background-repeat: no-repeat;
  }
  .selectionInput{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    input {
      display: none;
      &:checked + label{
        border: 4px solid;
        border-image-source: linear-gradient(var(--main-color), var(--secondary-color));
        border-image-slice: 20;
        background-color: rgba(0, 0, 0, 0.64);
        animation: fadeIn 0.2s;
        span.false-input{
          display:none;
        }
        i.check{
          display:block;
          animation: fadeIn 0.2s;
        }
      }
    }
    label{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      margin: 0;
      cursor: pointer;
      span.false-input{
        display: flex;
        width: 20px;
        height: 20px;
        margin: 3px 10px 3px 3px;
        padding:4px;
        background: white;
        vertical-align: middle;
        border: 1px solid #aeaeae;
        border-radius: 5px;
        position: absolute;
        top: 0;
        right: -16px;
      }
      i.check{
        display: none;
        font-size:35px;
        background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .expand{
        position:absolute;
        bottom:0;
        left:0;
        font-size: 18px;
        margin: 2px;
        color: white;
        text-shadow: 0 0 5px #aeaeae;
      }
    }
  }
  @media only screen and ( max-width: 769px ) {
    justify-content: center;
  }
}
.imageChoiceTitle{
  font-size: 15px;
}
@keyframes fadeIn{
  from{opacity:0;}
  to{opacity:1;}
}
</style>
